import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import minus from "pages/PurchasePage/imgs/minus.svg";
import plus from "pages/PurchasePage/imgs/plus.svg";
import { setPopupHide, setPopupShow } from "Redux/reducers/appReducer";
import { setCartItem } from "Redux/reducers/cartReducer";

const PurchaseChoiceGoods = () => {
    const dispatch = useDispatch();
    const { goodsData } = useSelector((state) => state.goods);
    const { popup } = useSelector((state) => state.app);
    const languages = useSelector((state) => state.languages);
    const timeoutRef = useRef(null);
    const [goodsList, setGoodsList] = useState(goodsData.map((item) => ({ ...item, quantity: 1 })));
    const lang = languages.language;

    const showPopupTimeout = () => {
        dispatch(setPopupShow(languages.purchasePageAddedToCart));

        clearTimeout(timeoutRef.current);

        timeoutRef.current = setTimeout(() => {
            dispatch(setPopupHide());
        }, 2000);
    };

    const addQuantity = (id) => {
        const updatedGoodsList = goodsList.map((item) => {
            if (item.id === id) item.quantity++;

            return item;
        });
        setGoodsList(updatedGoodsList);
    };

    const removeQuantity = (id) => {
        const updatedGoodsList = goodsList.map((item) => {
            if (item.id === id && item.quantity >= 1) item.quantity--;

            return item;
        });
        setGoodsList(updatedGoodsList);
    };

    const onQuantityChange = (e, id) => {
        const updatedGoodsList = goodsList.map((item) => {
            if (item.id === id) item.quantity = e.target.value;

            return item;
        });
        setGoodsList(updatedGoodsList);
    };

    const addToCartHandler = (item) => {
        if (item.quantity) {
            if (popup) {
                dispatch(setPopupHide());
                setTimeout(showPopupTimeout, 0);
            } else {
                showPopupTimeout();
            }

            const updatedGoodsList = goodsList.map((goodsItem) => {
                if (goodsItem.id === item.id) goodsItem.quantity = 1;

                return goodsItem;
            });

            setGoodsList(updatedGoodsList);
            dispatch(setCartItem(item));
        }
    };

    return (
        <div className="purchasePage__step1">
            <div className="container">
                {goodsList.map((item) => {
                    const quantity = item.quantity;
                    const price = (item.price * quantity).toFixed(2);
                    const calcPrepayment = (item.price * quantity) / 10;
                    const prepayment = calcPrepayment > 2000 ? 2000 : calcPrepayment.toFixed(2);

                    return (
                        <div className="purchasePage__item" key={item.id}>
                            <img className="purchasePage__itemImg" alt="" src={item.image} />
                            <h3>{item.name[lang]}</h3>
                            <div className="purchasePage__pricesRow">
                                <div className="price">
                                    <label>{languages.purchasePagePrice}</label>
                                    <h4>{price} $</h4>
                                </div>

                                <div className="price">
                                    <label>{languages.purchasePagePrepay}</label>
                                    <h4>{prepayment} $</h4>
                                </div>
                            </div>
                            <div className="purchasePage__quantityRow">
                                <div className="purchasePage__quantityHolder">
                                    <label>{languages.purchasePageQuantity}</label>
                                    <div className="purchasePage__quantity">
                                        <img
                                            onClick={() => {
                                                removeQuantity(item.id);
                                            }}
                                            className="minus"
                                            alt=""
                                            src={minus}
                                        />
                                        <input
                                            onChange={(e) => {
                                                onQuantityChange(e, item.id);
                                            }}
                                            min="0"
                                            type="number"
                                            value={quantity}
                                        />
                                        <img
                                            onClick={() => {
                                                addQuantity(item.id);
                                            }}
                                            className="plus"
                                            src={plus}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <button
                                    onClick={() => {
                                        addToCartHandler({ ...item, quantity });
                                    }}
                                    className="blueButton"
                                >
                                    {languages.purchasePageAdd}
                                </button>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default PurchaseChoiceGoods;
