import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

import { fetchGoodsData } from "Redux/reducers/goodsReducer";
import { hideCart, setCart, showCart } from "Redux/reducers/cartReducer";
import { createOrder, sendMailToCustomer } from "helpers/purchase";
import { sendOrder } from "helpers/services/amocrm";
import { auth } from "helpers/firebase";
import { LocalStorage } from "helpers/localstorage";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import Cart from "components/Cart/Cart";
import Stripe from "components/Stripe/Stripe";
import PurchaseCustomerForm from "components/purchase/PurchaseCustomerForm";
import PurchasePhoneConfirmation from "components/purchase/PurchasePhoneConfirmation";
import PurchaseChoiceGoods from "components/purchase/PurchaseChoiceGoods";
import Spinner from "components/Spinner/Spinner";
import check from "./imgs/checkRound.svg";
import checkBig from "./imgs/checkRoundBig.svg";
import "./PurchasePage.scss";

const initForm = {
    name: "",
    lastName: "",
    email: "",
    phone: "",
    deliveryType: "storage",
    deliveryCountry: "cyprus",
    deliveryAddress: "",
};

export default function PurchasePage() {
    const dispatch = useDispatch();
    const languages = useSelector((state) => state.languages);
    const { goodsData, isGoodsLoading, isGoodsError } = useSelector((state) => state.goods);
    const { cart, isShowCart } = useSelector((state) => state.cart);
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [isPhoneConfirmed, setIsPhoneConfirmed] = useState(false);
    const [isSuccessfulPayment, setIsSuccessfulPayment] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [form, setForm] = useState(initForm);
    const [totalPrice, setTotalPrice] = useState(0);

    useEffect(() => {
        if (!goodsData.length) {
            dispatch(fetchGoodsData());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        const savedCart = LocalStorage.getCart();

        if (savedCart && goodsData.length) {
            const updatedCart = savedCart.map((cartItem) => {
                const goodsItem = goodsData.find(({ id }) => id === cartItem.id);
                return {
                    ...cartItem,
                    price: goodsItem?.price || cartItem.price,
                };
            });

            dispatch(setCart(updatedCart));
        }
    }, [dispatch, goodsData]);

    useEffect(() => {
        if (cart.length) {
            const getTotalPrice = cart.reduce((sum, item) => sum + item.price * item.quantity, 0);

            setTotalPrice(getTotalPrice);
        }
    }, [cart]);

    const toggleCartOpen = () => {
        if (isShowCart) {
            dispatch(hideCart());
        } else {
            dispatch(showCart());
        }
    };

    const previousStep = () => {
        if (step !== 1 && step !== 3) {
            setStep(step - 1);
        }
    };

    const nextStep = () => {
        if (step !== 3 && cart.length) {
            if (step === 2 && !form.phone) return;

            setStep(step + 1);
        }
    };

    const setSuccessfulPayment = async () => {
        setIsLoading(true);

        const order = createOrder(form, cart, totalPrice);

        const saveOrder = await sendOrder(order);

        const sendMail = await sendMailToCustomer(form.email, cart, totalPrice);

        if (!saveOrder) {
            console.log("Ошибка отправки данных в crm");
        }

        if (!sendMail) {
            console.log("Ошибка отправки email заказчику");
        }

        dispatch(setCart([]));
        setIsLoading(false);
        setIsSuccessfulPayment(true);
    };

    const sendCode = (phone, resend = false) => {
        if (!window.confirmationResult || resend) {
            setIsLoading(true);

            // if (!window.recaptchaVerifier) {
            //     window.recaptchaVerifier = new RecaptchaVerifier("sign-in-button", { size: "invisible" }, auth);
            // }

            signInWithPhoneNumber(auth, phone, true)
                .then((confirmationResult) => {
                    window.confirmationResult = confirmationResult;
                    setStep(3);
                    setIsLoading(false);
                })
                .catch((e) => {
                    setIsError(true);
                    setIsLoading(false);
                    setTimeout(() => {
                        setIsError(false);
                    }, 3000);
                });
        } else {
            setStep(3);
        }
    };

    const setStepHandler = (value) => {
        if (value === 1) {
            if (isSuccessfulPayment) {
                setIsSuccessfulPayment(false);
            }

            setStep(value);
        }

        if (value === 2 && cart.length) {
            setStep(value);
        }

        if (value === 3 && cart.length && form.phone) {
            // sendCode(form);
            setStep(3);
        }
    };

    const setFormHandler = (completedForm) => {
        const isResend = form.phone !== completedForm.phone;

        setForm(completedForm);
        // sendCode(completedForm.phone, isResend);
    };

    return (
        <div className="purchasePage">
            <Header />

            {isGoodsError || isGoodsLoading ? (
                <div className="purchasePage__empty-block">
                    <div className="container">
                        {isGoodsError && <div className="error-message">{languages.purchasePageDataLoadingError}</div>}
                        {isGoodsLoading && <Spinner />}
                    </div>
                </div>
            ) : (
                <>
                    <div className="purchasePage__controlsRow">
                        <div className="container">
                            <div className="purchasePage__breadcrumbRow">
                                <label>{languages.headerServices}</label>
                                <label>{languages.servicesMoneyBuy}</label>
                            </div>
                            <div className="purchasePage__titleRow">
                                <h1>{languages.purchasePageBookOnline}</h1>
                                <div className="buttons">
                                    <button
                                        onClick={previousStep}
                                        className={`grayButton ${step === 1 || step === 3 ? "disabled" : ""}`}
                                    >
                                        {languages.purchasePageBack}
                                    </button>
                                    <button
                                        onClick={nextStep}
                                        className={`blueButton ${
                                            step === 3 || !cart.length || (step === 2 && !form.phone) ? "disabled" : ""
                                        }`}
                                    >
                                        {languages.purchasePageNext}
                                    </button>
                                </div>
                            </div>
                            <div className="purchasePage__controls">
                                <div className="purchasePage__steps">
                                    <button
                                        onClick={() => {
                                            setStepHandler(1);
                                        }}
                                        className={step === 1 ? "purchasePage__step active" : "purchasePage__step"}
                                    >
                                        {languages.purchasePageStep1}
                                    </button>
                                    <button
                                        onClick={() => {
                                            setStepHandler(2);
                                        }}
                                        className={step === 2 ? "purchasePage__step active" : "purchasePage__step"}
                                    >
                                        {languages.purchasePageStep2}
                                    </button>
                                    <button
                                        onClick={() => {
                                            setStepHandler(3);
                                        }}
                                        className={step === 3 ? "purchasePage__step active" : "purchasePage__step"}
                                    >
                                        {languages.purchasePageStep3}
                                    </button>
                                </div>

                                <div className="cart__wrapper">
                                    <button onClick={toggleCartOpen} className="purchasePage__cart">
                                        {languages.purchasePageCart} ({cart.length})
                                    </button>

                                    {isShowCart && <Cart nextStep={nextStep} totalPrice={totalPrice} />}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="purchasePage__steps-wrapper">
                        {isShowCart && <div className="purchasePage__steps-darken" />}
                        {step === 1 && <PurchaseChoiceGoods />}
                        {step === 2 && (
                            <PurchaseCustomerForm
                                initForm={form}
                                setFormHandler={setFormHandler}
                                isLoading={isLoading}
                                isError={isError}
                                totalPrice={totalPrice}
                                setStep={setStep}
                            />
                        )}
                        {step === 3 && (
                            <div className="purchasePage__step3">
                                <div className="container">
                                    {!isSuccessfulPayment ? (
                                        <div className="purchasePage__confirmationBlock">
                                            {isLoading ? (
                                                <Spinner />
                                            ) : (
                                                <>
                                                    <Stripe
                                                        email={form.email}
                                                        totalPrice={totalPrice}
                                                        languages={languages}
                                                        setSuccessfulPayment={setSuccessfulPayment}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="purchasePage__phoneConfirmation">
                                            <div className="row">
                                                <h2>{languages.purchasePagePaymentSuccessful}</h2>
                                                <img src={check} alt="" />
                                            </div>
                                            <p className="margin">
                                                {languages.purchasePageWeSentMessage} {form.email}
                                            </p>
                                            <img className="mob" src={checkBig} alt="" />
                                            <div className="row">
                                                <button
                                                    onClick={() => {
                                                        navigate("/services");
                                                    }}
                                                    className="blueButton"
                                                >
                                                    {languages.purchasePageCheckOther}
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        navigate("/");
                                                    }}
                                                    className="grayButton"
                                                >
                                                    {languages.purchasePageBackOnMain}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>

                    <div id="sign-in-button" style={{ display: "none" }} />
                </>
            )}

            <Footer />
        </div>
    );
}
