import React from 'react';
import './ZeroFeesPage.scss';
import Header from "../../components/Header/Header";
import OtherBanner from "../../components/OtherBanner/OtherBanner";
import InfoPoints from "../../components/InfoPoints/InfoPoints";
import AboutService from "../../components/AboutService/AboutService";
import OtherOffers from "../../components/OtherOffers/OtherOffers";
import Services from "../../components/Services/Services";
import CallUs from "../../components/CallUs/CallUs";
import Footer from "../../components/Footer/Footer";
import {useSelector} from "react-redux";

export default function ZeroFeesPage() {
    let languages = useSelector(state => state.languages)

    return (
        <div className="ZeroFeesPage">
            <Header/>
            <OtherBanner page="ZeroFees"/>
            <AboutService type={5}/>
            <OtherOffers type={1}/>
            <Services page="all"/>
            <CallUs text={languages.zeroFeesPageCallUs}/>
            <Footer/>
        </div>
    );
}