import React from 'react';
import './SupportPage.scss';
import Header from "../../components/Header/Header";
import Services from "../../components/Services/Services";
import FAQ from "../../components/FAQ/FAQ";
import CallUs from "../../components/CallUs/CallUs";
import Footer from "../../components/Footer/Footer";
import {useSelector} from "react-redux";

export default function SupportPage() {
    let languages = useSelector(state => state.languages)

    return (
        <div className="supportPage">
            <Header/>
            <div className="supportPage__content">
                <div className="container">
                    <label>{languages.headerSupport}</label>
                </div>
            </div>
            <FAQ/>
            <CallUs type={2} text={languages.supportPageCallUs}/>
            <Footer/>
        </div>
    );
}