import React from 'react';
import './InfoPoints.scss';

import point1 from './imgs/point1.png'
import point2 from './imgs/point2.png'
import point3 from './imgs/point3.png'
import point4 from './imgs/point4.png'

import point1_2 from './imgs/point1_2.png'
import point2_2 from './imgs/point2_2.png'
import point3_2 from './imgs/point3_2.png'
import point4_2 from './imgs/point4_2.png'

import point1_4 from './imgs/point1_4.png'
import point2_4 from './imgs/point2_4.png'
import point3_4 from './imgs/point3_4.png'
import point4_4 from './imgs/point4_4.png'
import {useSelector} from "react-redux";

export default function InfoPoints(props) {
    let languages = useSelector(state => state.languages)
    if(props.type === 1){
        return (
            <div className="infoPoints">
                <div className="container">
                    <h2>{languages.infoPointsOurCompany}</h2>
                    <div className="infoPoints__row">
                        <div className="infoPoints__card">
                            <h3 className="thin">01</h3>
                            <h3>{languages.infoPointsNoLimits}</h3>
                            <p>{languages.infoPointsFastKYC}</p>
                            <img src={point1} alt=""/>
                        </div>
                        <div className="infoPoints__card">
                            <h3 className="thin">02</h3>
                            {languages.infoPointsLocated}
                            <p>{languages.infoPointsEasyVisa}</p>
                            <img src={point2} alt=""/>
                        </div>
                        <div className="infoPoints__card">
                            <h3 className="thin">03</h3>
                            {languages.infoPointsSecureClients}
                            <p>{languages.infoPointsSecureClientsText}</p>
                            <img src={point3} alt=""/>
                        </div>
                        <div className="infoPoints__card">
                            <h3 className="thin">04</h3>
                            {languages.infoPointsInstantGiveaway}
                            {languages.infoPointsTakeAny}
                            <img src={point4} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    if(props.type === 2){
        return (
            <div className="infoPoints">
                <div className="container">
                    <h2>{languages.infoPointsOurCompany}</h2>
                    <div className="infoPoints__row">
                        <div className="infoPoints__card">
                            <h3 className="thin">01</h3>
                            <h3>{languages.infoPointsNoLimits}</h3>
                            <p>{languages.infoPointsFastKYC}</p>
                            <img src={point1_2} alt=""/>
                        </div>
                        <div className="infoPoints__card">
                            <h3 className="thin">02</h3>
                            {languages.infoPointsLocated}
                            <p>{languages.infoPointsEasyVisa}</p>
                            <img src={point2_2} alt=""/>
                        </div>
                        <div className="infoPoints__card">
                            <h3 className="thin">03</h3>
                            {languages.infoPointsSecureClients}
                            <p>{languages.infoPointsSecureClientsText}</p>
                            <img src={point3_2} alt=""/>
                        </div>
                        <div className="infoPoints__card">
                            <h3 className="thin">04</h3>
                            {languages.infoPointsInstantGiveaway}
                            {languages.infoPointsTakeAny}
                            <img src={point4_2} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        );

    }
    if(props.type === 4) {
        return (
            <div className="infoPoints">
                <div className="container">
                    <h2>{languages.infoPointsBuyProcess}</h2>
                    <div className="infoPoints__row">
                        <div className="infoPoints__card infoPoints__card-small">
                            <h3 className="thin">01</h3>
                            {languages.infoPointsYouLeaveReq}
                            <img src={point1_4} alt=""/>
                        </div>
                        <div className="infoPoints__card infoPoints__card-small">
                            <h3 className="thin">02</h3>
                            <h3>{languages.infoPointsOurExperts}</h3>
                            <img src={point2_4} alt=""/>
                        </div>
                        <div className="infoPoints__card infoPoints__card-small">
                            <h3 className="thin">03</h3>
                            <h3>{languages.infoPointsSign}</h3>
                            <img src={point3_4} alt=""/>
                        </div>
                        <div className="infoPoints__card infoPoints__card-small">
                            <h3 className="thin">04</h3>
                            {languages.infoPointsBuyingYour}
                            <img src={point4_4} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}