import React from "react";
import "./WeSellMetals.scss";
import MetalPrices from "../../../../components/MetalPrices/MetalPrices";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function WeSellMetals() {
    let languages = useSelector((state) => state.languages);
    let navigate = useNavigate();

    return (
        <div className="weSellMetals">
            <div className="container">
                <h2>{languages.weSellMetals}</h2>
                <div className="weSellMetals__row">
                    <MetalPrices />
                    <div className="weSellMetals__text">
                        {languages.weSellMetalsMainPageText}
                        <p className="mob">{languages.weSellMetalsYouShouldntPay}</p>
                        <div className="weSellMetals__buttonsRow">
                            <button
                                onClick={() => {
                                    navigate("/buyMetals");
                                }}
                                className="blueButton"
                            >
                                {languages.weSellMetalsBuy}
                            </button>
                            <button
                                onClick={() => {
                                    navigate("/sellMetals");
                                }}
                                className="grayButton"
                            >
                                {languages.weSellMetalsSell}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
