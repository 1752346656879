const cartKey = process.env.REACT_APP_LOCALSTORAGE_CART_KEY || 'cart'

export const LocalStorage = {
    getCart() {
        return JSON.parse(localStorage.getItem(cartKey));
    },

    setCart(cart) {
        localStorage.setItem(cartKey, JSON.stringify(cart));
    },

    clearCart() {
        localStorage.removeItem(cartKey);
    },
};
