import React from 'react';
import './CheckingMetals.scss';
import Header from "../../components/Header/Header";
import OtherBanner from "../../components/OtherBanner/OtherBanner";
import AboutService from "../../components/AboutService/AboutService";
import OtherOffers from "../../components/OtherOffers/OtherOffers";
import Services from "../../components/Services/Services";
import CallUs from "../../components/CallUs/CallUs";
import Footer from "../../components/Footer/Footer";
import {useSelector} from "react-redux";

export default function CheckingMetals() {
    let languages = useSelector(state => state.languages)
    return (
        <div className="checkingMetals">
            <Header/>
            <OtherBanner page="CheckingMetals"/>
            <AboutService type={7}/>
            <OtherOffers type={4}/>
            <Services page="all"/>
            <CallUs text={languages.checkingMetalsPageCallUs}/>
            <Footer/>
        </div>
    );
}