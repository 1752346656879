import React from 'react';
import './OtherBanner.scss';

import zeroFeesImg from './imgs/zeroFeesImg.jpg';
import fullInsuranceImg from './imgs/fullInsuranceImg.jpg';
import checkingMetalsImg from './imgs/checkingMetalsImg.jpg';
import unlimitedImportImg from './imgs/unlimitedImportImg.jpg';
import basel3Img from './imgs/basel3Img.jpg';
import fullConfidenceImg from './imgs/fullConfidenceImg.jpg';
import aboutPageImg from './imgs/AboutPageImg.jpg';
import storagePageImg from './imgs/storagePageImg.jpg';
import {setFormOpened} from "../../Redux/reducers/formReducer";
import {useDispatch, useSelector} from "react-redux";

export default function OtherBanner(props) {
    let dispatch = useDispatch();
    let languages = useSelector(state => state.languages)
    if(props.page === 'ZeroFees'){
        return (
            <div className="otherBanner">
                <div className="container">
                    {languages.otherBannerZeroFees}
                    {languages.otherBannerZeroFeesText}
                    <button onClick={() => {dispatch(setFormOpened())}} className="blueButton">{languages.headerBecame}</button>
                </div>
                <div className="container container-big">
                    <img src={zeroFeesImg} alt=""/>
                </div>
            </div>
        );
    }
    if(props.page === 'FullInsurance'){
        return (
            <div className="otherBanner">
                <div className="container">
                    {languages.otherBannerFullInsurance}
                    <p>{languages.otherBannerFullInsuranceText}</p>
                    <button onClick={() => {dispatch(setFormOpened())}} className="blueButton">{languages.headerBecame}</button>
                </div>
                <div className="container container-big">
                    <img src={fullInsuranceImg} alt=""/>
                </div>
            </div>
        );
    }
    if(props.page === 'CheckingMetals'){
        return (
            <div className="otherBanner">
                <div className="container">
                    {languages.otherBannerCheckingMetals}
                    {languages.otherBannerCheckingMetalsText}
                    <button onClick={() => {dispatch(setFormOpened())}} className="blueButton">{languages.headerBecame}</button>
                </div>
                <div className="container container-big">
                    <img src={checkingMetalsImg} alt=""/>
                </div>
            </div>
        );
    }
    if(props.page === 'UnlimitedImport'){
        return (
            <div className="otherBanner">
                <div className="container">
                    <h1>{languages.otherBannerUnlimitedImport}</h1>
                    {languages.otherBannerUnlimitedImportText}
                    <button onClick={() => {dispatch(setFormOpened())}} className="blueButton">{languages.headerBecame}</button>
                </div>
                <div className="container container-big">
                    <img src={unlimitedImportImg} alt=""/>
                </div>
            </div>
        );
    }
    if(props.page === 'Basel3Page'){
        return (
            <div className="otherBanner">
                <div className="container">
                    {languages.otherBannerBasel3Page}
                    {languages.otherBannerBasel3PageText}
                    <button onClick={() => {dispatch(setFormOpened())}} className="blueButton">{languages.headerBecame}</button>
                </div>
                <div className="container container-big">
                    <img src={basel3Img} alt=""/>
                </div>
            </div>
        );
    }
    if(props.page === 'FullConfidence'){
        return (
            <div className="otherBanner">
                <div className="container">
                    {languages.otherBannerFullConfidence}
                    {languages.otherBannerFullConfidenceText}
                    <button onClick={() => {dispatch(setFormOpened())}} className="blueButton">{languages.headerBecame}</button>
                </div>
                <div className="container container-big">
                    <img src={fullConfidenceImg} alt=""/>
                </div>
            </div>
        );
    }
    if(props.page === 'AboutPage'){
        return (
            <div className="otherBanner">
                <div className="container">
                    {languages.otherBannerAboutPage}
                    {languages.otherBannerAboutPageText}
                </div>
                <div className="container container-big">
                    <img src={aboutPageImg} alt=""/>
                </div>
            </div>
        );
    }
    if(props.page === 'StoragePage'){
        return (
            <div className="otherBanner">
                <div className="container">
                    <h1>{languages.otherBannerStoragePage}</h1>
                    {languages.otherBannerStoragePageText}
                </div>
                <div className="container container-big">
                    <img src={storagePageImg} alt=""/>
                </div>
            </div>
        );
    }
}