export const changeLanguageInURL = (newURLPrefix) => {
    const URLPathsSplitted = window.location.pathname.split("/");

    URLPathsSplitted[1] = newURLPrefix;

    let preparedURL = "";

    URLPathsSplitted.forEach((path, i) => {
        if (i !== URLPathsSplitted.length - 1) {
            preparedURL += path + "/";
        } else {
            preparedURL += path;
        }
    });

    window.history.pushState(null, null, preparedURL);
};
