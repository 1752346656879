import { API_URL } from "../../config";

const SET_GOODS_LIST = "SET_GOODS_LIST";
const SET_LOADING = "SET_LOADING";
const SET_ERROR = "SET_ERROR";

const defaultState = {
    goodsData: [],
    isGoodsLoading: false,
    isGoodsError: false,
};

export default function goodsReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_GOODS_LIST:
            return {
                ...state,
                goodsData: action.payload,
            };
        case SET_LOADING:
            return {
                ...state,
                isGoodsLoading: action.payload,
            };
        case SET_ERROR:
            return {
                ...state,
                isGoodsError: action.payload,
            };
        default:
            return state;
    }
}

export const fetchGoodsData = () => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING, payload: true });

        const response = await fetch(`${API_URL}/prices.php`);

        if (!response.ok) {
            dispatch({ type: SET_ERROR, payload: true });
            return;
        }

        dispatch({ type: SET_GOODS_LIST, payload: await response.json() });
    } catch (e) {
        console.log(e);
        dispatch({ type: SET_ERROR, payload: true });
    } finally {
        dispatch({ type: SET_LOADING, payload: false });
    }
};
