import React, {useState} from 'react';
import './FAQ.scss';
import {useSelector} from "react-redux";

export default function FAQ() {
    let languages = useSelector(state => state.languages)
    const [isFirstOpened, setIsFirstOpened] = useState(false);
    const [isSecondOpened, setIsSecondOpened] = useState(false);
    const [isThirdOpened, setIsThirdOpened] = useState(false);
    const [isFourthOpened, setIsFourthOpened] = useState(false);

    function changeFourth(event) {
        setIsFourthOpened(!isFourthOpened);
    }

    function changeThird(event) {
        setIsThirdOpened(!isThirdOpened);
    }

    function changeSecond(event) {
        setIsSecondOpened(!isSecondOpened);
    }

    function changeFirst(event) {
        setIsFirstOpened(!isFirstOpened);
    }

    return (
        <div className="faq">
            <div className="container faq__h2">
                <h2>{languages.faq}</h2>
            </div>
            <div className={isFirstOpened ? "faq__row big opened" : "faq__row big"} onClick={changeFirst}>
                <div className="container">
                    <div className="faq__textRow">
                        <h3 className="thin">{languages.faqQ1}</h3>
                        <div className="faq__plus">
                            <div className="horizontal"/>
                            <div className="vertical"/>
                        </div>
                    </div>
                    {languages.faqA1}
                </div>
            </div>
            <div className={isSecondOpened ? "faq__row opened" : "faq__row"} onClick={changeSecond}>
                <div className="container">
                    <div className="faq__textRow">
                        <h3 className="thin">{languages.faqQ2}</h3>
                        <div className="faq__plus">
                            <div className="horizontal"/>
                            <div className="vertical"/>
                        </div>
                    </div>
                    {languages.faqA2}
                </div>
            </div>
            <div className={isThirdOpened ? "faq__row opened" : "faq__row"} onClick={changeThird}>
                <div className="container">
                    <div className="faq__textRow">
                        <h3 className="thin">{languages.faqQ3}</h3>
                        <div className="faq__plus">
                            <div className="horizontal"/>
                            <div className="vertical"/>
                        </div>
                    </div>
                    {languages.faqA3}
                </div>
            </div>
            <div className={isFourthOpened ? "faq__row opened" : "faq__row"} onClick={changeFourth}>
                <div className="container">
                    <div className="faq__textRow">
                        <h3 className="thin">{languages.faqQ4}</h3>
                        <div className="faq__plus">
                            <div className="horizontal"/>
                            <div className="vertical"/>
                        </div>
                    </div>
                    <p>{languages.faqA4}</p>
                </div>
            </div>
        </div>
    );
}