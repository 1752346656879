import React from 'react';
import './OtherOffers.scss';

import arrowRight from './imgs/arrowRight.svg'
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

export default function OtherOffers(props) {
    let languages = useSelector(state => state.languages)
    let navigate = useNavigate();
    if(props.type === 1){
        return (
            <div className="otherOffers">
                <div className="container">
                    <h2>{languages.otherOffers}</h2>
                </div>
                <div onClick={()=>{navigate('/fullInsurance')}} className="otherOffers__offerRow otherOffers__offerRow2">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersFullInsurance}</h3>
                        <p>{languages.otherOffersWeWorksWith}</p>
                        <img src={arrowRight}/>
                    </div>
                </div>
                <div onClick={()=>{navigate('/checkingMetals')}} className="otherOffers__offerRow otherOffers__offerRow3">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersTakeMetals}</h3>
                        <p>{languages.otherOffersYouSholdnt}</p>
                        <img src={arrowRight}/>
                    </div>
                </div>
                <div onClick={()=>{navigate('/unlimitedExport')}} className="otherOffers__offerRow otherOffers__offerRow4">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersUnlimitedExport}</h3>
                        {languages.otherOffersYouCanTake}
                        <img src={arrowRight}/>
                    </div>
                </div>
                <div onClick={()=>{navigate('/basel3')}} className="otherOffers__offerRow otherOffers__offerRow5">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersBasel3}</h3>
                        <p>{languages.otherOffersIn2023}</p>
                        <img src={arrowRight}/>
                    </div>
                </div>
                <div onClick={()=>{navigate('/fullConfidence')}} className="otherOffers__offerRow otherOffers__offerRow6">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersFullConfidence}</h3>
                        <p>{languages.otherOffersWeSecure}</p>
                        <img src={arrowRight}/>
                    </div>
                </div>
            </div>
        );
    }
    if(props.type === 2){
        return (
            <div className="otherOffers">
                <div className="container">
                    <h2>{languages.otherOffers}</h2>
                </div>
                <div onClick={()=>{navigate('/zeroFees')}} className="otherOffers__offerRow otherOffers__offerRow1">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersZeroFees}</h3>
                        {languages.otherOffersYouShouldntPay}
                        <img src={arrowRight}/>
                    </div>
                </div>
                <div onClick={()=>{navigate('/checkingMetals')}} className="otherOffers__offerRow otherOffers__offerRow3">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersTakeMetals}</h3>
                        <p>{languages.otherOffersYouSholdnt}</p>
                        <img src={arrowRight}/>
                    </div>
                </div>
                <div onClick={()=>{navigate('/unlimitedExport')}} className="otherOffers__offerRow otherOffers__offerRow4">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersUnlimitedExport}</h3>
                        {languages.otherOffersYouCanTake}
                        <img src={arrowRight}/>
                    </div>
                </div>
                <div onClick={()=>{navigate('/basel3')}} className="otherOffers__offerRow otherOffers__offerRow5">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersBasel3}</h3>
                        <p>{languages.otherOffersIn2023}</p>
                        <img src={arrowRight}/>
                    </div>
                </div>
                <div onClick={()=>{navigate('/fullConfidence')}} className="otherOffers__offerRow otherOffers__offerRow6">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersFullConfidence}</h3>
                        <p>{languages.otherOffersWeSecure}</p>
                        <img src={arrowRight}/>
                    </div>
                </div>
            </div>
        );
    }
    if(props.type === 3){
        return (
            <div className="otherOffers">
                <div className="container">
                    <h2>{languages.otherOffers}</h2>
                </div>
                <div onClick={()=>{navigate('/zeroFees')}} className="otherOffers__offerRow otherOffers__offerRow1">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersZeroFees}</h3>
                        {languages.otherOffersYouShouldntPay}
                        <img src={arrowRight}/>
                    </div>
                </div>
                <div onClick={()=>{navigate('/fullInsurance')}} className="otherOffers__offerRow otherOffers__offerRow2">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersFullInsurance}</h3>
                        <p>{languages.otherOffersWeWorksWith}</p>
                        <img src={arrowRight}/>
                    </div>
                </div>
                <div onClick={()=>{navigate('/unlimitedExport')}} className="otherOffers__offerRow otherOffers__offerRow4">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersUnlimitedExport}</h3>
                        {languages.otherOffersYouCanTake}
                        <img src={arrowRight}/>
                    </div>
                </div>
                <div onClick={()=>{navigate('/basel3')}} className="otherOffers__offerRow otherOffers__offerRow5">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersBasel3}</h3>
                        <p>{languages.otherOffersIn2023}</p>
                        <img src={arrowRight}/>
                    </div>
                </div>
                <div onClick={()=>{navigate('/fullConfidence')}} className="otherOffers__offerRow otherOffers__offerRow6">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersFullConfidence}</h3>
                        <p>{languages.otherOffersWeSecure}</p>
                        <img src={arrowRight}/>
                    </div>
                </div>
            </div>
        );
    }
    if(props.type === 4){
        return (
            <div className="otherOffers">
                <div className="container">
                    <h2>{languages.otherOffers}</h2>
                </div>
                <div onClick={()=>{navigate('/zeroFees')}} className="otherOffers__offerRow otherOffers__offerRow1">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersZeroFees}</h3>
                        {languages.otherOffersYouShouldntPay}
                        <img src={arrowRight}/>
                    </div>
                </div>
                <div onClick={()=>{navigate('/fullInsurance')}} className="otherOffers__offerRow otherOffers__offerRow2">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersFullInsurance}</h3>
                        <p>{languages.otherOffersWeWorksWith}</p>
                        <img src={arrowRight}/>
                    </div>
                </div>
                <div onClick={()=>{navigate('/checkingMetals')}} className="otherOffers__offerRow otherOffers__offerRow3">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersTakeMetals}</h3>
                        <p>{languages.otherOffersYouSholdnt}</p>
                        <img src={arrowRight}/>
                    </div>
                </div>
                <div onClick={()=>{navigate('/basel3')}} className="otherOffers__offerRow otherOffers__offerRow5">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersBasel3}</h3>
                        <p>{languages.otherOffersIn2023}</p>
                        <img src={arrowRight}/>
                    </div>
                </div>
                <div onClick={()=>{navigate('/fullConfidence')}} className="otherOffers__offerRow otherOffers__offerRow6">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersFullConfidence}</h3>
                        <p>{languages.otherOffersWeSecure}</p>
                        <img src={arrowRight}/>
                    </div>
                </div>
            </div>
        );
    }
    if(props.type === 5){
        return (
            <div className="otherOffers">
                <div className="container">
                    <h2>{languages.otherOffers}</h2>
                </div>
                <div onClick={()=>{navigate('/zeroFees')}} className="otherOffers__offerRow otherOffers__offerRow1">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersZeroFees}</h3>
                        {languages.otherOffersYouShouldntPay}
                        <img src={arrowRight}/>
                    </div>
                </div>
                <div onClick={()=>{navigate('/fullInsurance')}} className="otherOffers__offerRow otherOffers__offerRow2">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersFullInsurance}</h3>
                        <p>{languages.otherOffersWeWorksWith}</p>
                        <img src={arrowRight}/>
                    </div>
                </div>
                <div onClick={()=>{navigate('/checkingMetals')}} className="otherOffers__offerRow otherOffers__offerRow3">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersTakeMetals}</h3>
                        <p>{languages.otherOffersYouSholdnt}</p>
                        <img src={arrowRight}/>
                    </div>
                </div>
                <div onClick={()=>{navigate('/unlimitedExport')}} className="otherOffers__offerRow otherOffers__offerRow4">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersUnlimitedExport}</h3>
                        {languages.otherOffersYouCanTake}
                        <img src={arrowRight}/>
                    </div>
                </div>
                <div onClick={()=>{navigate('/fullConfidence')}} className="otherOffers__offerRow otherOffers__offerRow6">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersFullConfidence}</h3>
                        <p>{languages.otherOffersWeSecure}</p>
                        <img src={arrowRight}/>
                    </div>
                </div>
            </div>
        );
    }
    if(props.type === 6){
        return (
            <div className="otherOffers">
                <div className="container">
                    <h2>{languages.otherOffers}</h2>
                </div>
                <div onClick={()=>{navigate('/zeroFees')}} className="otherOffers__offerRow otherOffers__offerRow1">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersZeroFees}</h3>
                        {languages.otherOffersYouShouldntPay}
                        <img src={arrowRight}/>
                    </div>
                </div>
                <div onClick={()=>{navigate('/fullInsurance')}} className="otherOffers__offerRow otherOffers__offerRow2">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersFullInsurance}</h3>
                        <p>{languages.otherOffersWeWorksWith}</p>
                        <img src={arrowRight}/>
                    </div>
                </div>
                <div onClick={()=>{navigate('/checkingMetals')}} className="otherOffers__offerRow otherOffers__offerRow3">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersTakeMetals}</h3>
                        <p>{languages.otherOffersYouSholdnt}</p>
                        <img src={arrowRight}/>
                    </div>
                </div>
                <div onClick={()=>{navigate('/unlimitedExport')}} className="otherOffers__offerRow otherOffers__offerRow4">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersBasel3}</h3>
                        <p>{languages.otherOffersIn2023}</p>
                        <img src={arrowRight}/>
                    </div>
                </div>
                <div onClick={()=>{navigate('/basel3')}} className="otherOffers__offerRow otherOffers__offerRow5">
                    <div className="container">
                        <h3 className="thin">{languages.otherOffersUnlimitedExport}</h3>
                        {languages.otherOffersYouCanTake}
                        <img src={arrowRight}/>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className="otherOffers otherOffers-block">
            <div onClick={()=>{navigate('/zeroFees')}} className="otherOffers__offerRow otherOffers__offerRow1">
                <div className="container">
                    <h3 className="thin">{languages.otherOffersZeroFees}</h3>
                    {languages.otherOffersYouShouldntPay}
                    <img src={arrowRight}/>
                </div>
            </div>
            <div onClick={()=>{navigate('/fullInsurance')}} className="otherOffers__offerRow otherOffers__offerRow2">
                <div className="container">
                    <h3 className="thin">{languages.otherOffersFullInsurance}</h3>
                    <p>{languages.otherOffersWeWorksWith}</p>
                    <img src={arrowRight}/>
                </div>
            </div>
            <div onClick={()=>{navigate('/checkingMetals')}} className="otherOffers__offerRow otherOffers__offerRow3">
                <div className="container">
                    <h3 className="thin">{languages.otherOffersTakeMetals}</h3>
                    <p>{languages.otherOffersYouSholdnt}</p>
                    <img src={arrowRight}/>
                </div>
            </div>
            <div onClick={()=>{navigate('/unlimitedExport')}} className="otherOffers__offerRow otherOffers__offerRow4">
                <div className="container">
                    <h3 className="thin">{languages.otherOffersUnlimitedExport}</h3>
                    {languages.otherOffersYouCanTake}
                    <img src={arrowRight}/>
                </div>
            </div>
            <div onClick={()=>{navigate('/basel3')}} className="otherOffers__offerRow otherOffers__offerRow5">
                <div className="container">
                    <h3 className="thin">{languages.otherOffersBasel3}</h3>
                    <p>{languages.otherOffersIn2023}</p>
                    <img src={arrowRight}/>
                </div>
            </div>
            <div onClick={()=>{navigate('/fullConfidence')}} className="otherOffers__offerRow otherOffers__offerRow6">
                <div className="container">
                    <h3 className="thin">{languages.otherOffersFullConfidence}</h3>
                    <p>{languages.otherOffersWeSecure}</p>
                    <img src={arrowRight}/>
                </div>
            </div>
        </div>
    );
}