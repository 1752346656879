import React from 'react';
import './BuyMetalsPage.scss';
import Header from "../../components/Header/Header";
import Banner from "../../components/Banner/Banner";
import PictureWithText from "../../components/PictureWithText/PictureWithText";
import InfoPoints from "../../components/InfoPoints/InfoPoints";
import MetalPrices from "../../components/MetalPrices/MetalPrices";
import InfoTable from "../../components/InfoTable/InfoTable";
import CallUs from "../../components/CallUs/CallUs";
import Services from "../../components/Services/Services";
import Footer from "../../components/Footer/Footer";
import {useDispatch, useSelector} from "react-redux";
import {setFormOpened} from "../../Redux/reducers/formReducer";

export default function BuyMetalsPage() {
    let languages = useSelector(state => state.languages)

    let dispatch = useDispatch();
    return (
        <div className="buyMetalsPage">
            <Header color="gray"/>
            <Banner type={4}/>
            <PictureWithText/>
            <InfoPoints type={4}/>
            <div className="buyMetalsPage__pricesBlock">
                <div className="container">
                    <h2>{languages.buyMetalsPageStock}</h2>
                    <div className="buyMetalsPage__pricesRow">
                        <MetalPrices/>
                        <div className="buyMetalsPage__pricesText">
                            <p>{languages.buyMetalsPageOurClients}</p>
                            <button onClick={() => {dispatch(setFormOpened())}} className="blueButton">{languages.buyMetalsPageLeaveReq}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="buyMetalsPage__info">
                <div className="container">
                    {languages.buyMetalsPageInfo}
                    <InfoTable/>
                </div>
            </div>
            <CallUs text={languages.buyMetalsPageCallUs}/>
            <Services page="buyMetals"/>
            <Footer/>
        </div>
    );
}