import { useState } from "react";
import LanguagesDropdownMenu from "./LanguagesDropdownMenu";
import { useSelector } from "react-redux";
import "./SwitchLanguage.scss";

const SwitchLanguage = () => {
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
    const languages = useSelector((state) => state.languages);

    const toggleDropdown = () => {
        setDropdownIsOpen(!dropdownIsOpen);
    };

    return (
        <div className="switch-language" onClick={(e) => e.stopPropagation()}>
            <button className="switch-language__preferred" onClick={toggleDropdown}>
                {languages.language}
            </button>
            {dropdownIsOpen ? <LanguagesDropdownMenu hideComponent={toggleDropdown} /> : null}
        </div>
    );
};

export default SwitchLanguage;
