import React from 'react';
import './Documents.scss';
import smallArrowRight from "../../imgs/smallArrowRight.svg";
import file from './imgs/file.svg';
import {Link, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

export default function Documents() {
    let navigate = useNavigate();
    let languages = useSelector(state => state.languages)
    return (
        <div className="documents">
            <div className="container">
                <h2>{languages.documents}</h2>
                <div className="documents__row">

                    <div className="documents__doc">
                        <img className="documents__doc-img" src={file} alt=""/>
                        <h3>Basel III</h3>
                        <button  onClick={() => {window.location.href ='https://www.bis.org/bcbs/publ/d424_hlsummary.pdf'}} className="arrowButton">
                            <label>{languages.documentsReadDoc}</label>
                            <img alt="" src={smallArrowRight}/>
                        </button>
                    </div>
                    <div className="documents__doc">
                        <img className="documents__doc-img" src={file} alt=""/>
                        <h3>{languages.footerLicense}</h3>
                        <button onClick={() => {window.location.href = 'https://swiftglobal.cy/licenses'}} className="arrowButton">
                            <label>{languages.documentsReadDoc}</label>
                            <img alt="" src={smallArrowRight}/>
                        </button>
                    </div>
                    <Link to={'/policy'} className="documents__doc">
                        <img className="documents__doc-img" src={file} alt=""/>
                        <h3>{languages.footerPolicy}</h3>
                        <button onClick={() => {navigate('/policy')}} className="arrowButton">
                            <label>{languages.documentsReadDoc}</label>
                            <img alt="" src={smallArrowRight}/>
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
}