import React from 'react';
import './StoragePage.scss';
import Header from "../../components/Header/Header";
import AboutService from "../../components/AboutService/AboutService";
import OtherBanner from "../../components/OtherBanner/OtherBanner";
import Safety from "../../components/Safety/Safety";
import Documents from "../../components/Documents/Documents";
import Footer from "../../components/Footer/Footer";

export default function StoragePage() {
    return (
        <div className="storagePage">
            <Header/>
            <OtherBanner page="StoragePage"/>
            <Safety type={2}/>
            <Documents/>
            <Footer/>
        </div>
    );
}