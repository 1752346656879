import React from "react";

import logo from "./imgs/logo.svg";
import logo2 from "./imgs/llloyds.svg";
import inst from "./imgs/instagram.svg";
import facebook from "./imgs/facebook.svg";
import linked from "./imgs/linked.svg";
import btc from "./imgs/btc.svg";
import visa from "./imgs/visa.svg";
import mastercard from "./imgs/mastercard.svg";
import "./Footer.scss";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setChinese, setEnglish, setRussian } from "../../Redux/reducers/languagesReducer";
import brinks from "./imgs/brinks.png";

export default function Footer() {
    let languages = useSelector((state) => state.languages);
    const { prefix } = languages;

    let dispatch = useDispatch();
    return (
        <div id="footer">
            <div className="footer__firstLine">
                <div className="footer__container">
                    <div className="footer__row-top">
                        <div className="footer__links">
                            <h4>{languages.mainPageServices}</h4>
                            <Link to={`/${prefix}/storeMetals`}>{languages.servicesStoreMetals}</Link>
                            <Link to={`/${prefix}/storeMoney`}>{languages.servicesMoneyStore}</Link>
                            <Link to={`/${prefix}/buyMetals`}>{languages.servicesMoneyBuy}</Link>
                            <Link to={`/${prefix}/sellMetals`}>{languages.servicesMetalsSell}</Link>
                            <Link to={`/${prefix}/services`}>{languages.footerAllServices}</Link>
                        </div>
                        <div className="footer__links">
                            <h4>{languages.footerInformation}</h4>
                            <Link to={`/${prefix}/about`}>{languages.headerAbout}</Link>
                            <Link to={`/${prefix}/storage`}>{languages.headerStorage}</Link>
                            <Link to={`/${prefix}/contacts`}>{languages.headerContacts}</Link>
                            <Link to={`/${prefix}/support`}>{languages.headerSupport}</Link>
                        </div>
                        <div className="footer__links">
                            <h4>{languages.footerDocumentation}</h4>
                            <Link to="/policy">{languages.footerPolicy}</Link>
                            <a href="https://swiftglobal.cy/licenses">{languages.footerLicense}</a>
                            <a href="https://www.bis.org/bcbs/publ/d424_hlsummary.pdf">Basel III</a>
                        </div>
                        <div className="footer__logosCol">
                            <div className="footer__logosRow">
                                <img alt="" src={logo} />
                                <img alt="" src={logo2} />
                            </div>
                            <div className="footer__languages">
                                <button
                                    onClick={() => {
                                        dispatch(setRussian());
                                    }}
                                    className={languages.language === "RU" ? "active" : ""}
                                >
                                    RU
                                </button>
                                <button
                                    onClick={() => {
                                        dispatch(setEnglish());
                                    }}
                                    className={languages.language === "ENG" ? "active" : ""}
                                >
                                    ENG
                                </button>
                                <button
                                    onClick={() => {
                                        dispatch(setChinese());
                                    }}
                                    className={languages.language === "CHN" ? "active" : ""}
                                >
                                    CHN
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer__secondLine">
                <div className="footer__container">
                    <div className="footer__rowBottom">
                        <div className="footer__social">
                            <a href="/" className="footer__socialLink">
                                <img alt="" src={inst} />
                            </a>
                            <a href="/" className="footer__socialLink">
                                <img alt="" src={facebook} />
                            </a>
                            <a href="/" className="footer__socialLink">
                                <img alt="" src={linked} />
                            </a>
                        </div>
                        <div className="footer__payMethods">
                            <p className="footer__caption">{languages.footerPaymentMethod}</p>
                            <div className="footer__payRow">
                                <img alt="" src={btc} />
                                <img alt="" src={visa} />
                                <img alt="" src={mastercard} />
                            </div>
                        </div>
                        <div className="footer__deliveryMethods">
                            <p className="footer__caption">{languages.footerShippingMethod}</p>
                            <div className="footer__deliveryRow">
                                <img className="footer__deliveryMethod" alt="" src={brinks} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer__thirdLine">
                <p className="footer__credits">{languages.footerCredits}</p>
            </div>
        </div>
    );
}
