import React from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

import { GOOGLE_MAPS_API_KEY } from "config";

const ContactMap = () => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    });

    return (
        <>
            {isLoaded && (
                <GoogleMap
                    zoom={12}
                    center={{ lat: 34.686503, lng: 33.049975 }}
                    mapContainerClassName="map-container"
                >
                    <Marker position={{ lat: 34.686503, lng: 33.049975 }} />
                </GoogleMap>
            )}
        </>
    );
};

export default ContactMap;
