import React from "react";
import { useSelector } from "react-redux";

import checked from "./img/checked.svg";
import "./Popup.css";

export default function Popup() {
    const { popup } = useSelector((state) => state.app);

    return (
      <div className={`popup ${popup ? 'showed' : ''}`}>
          <div className="popup__content">
              <img src={checked} alt="" />
              <p>{popup}</p>
          </div>
      </div>
    );
}
