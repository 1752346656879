import React from 'react';
import './SellMetalsPage.scss';
import Banner from "../../components/Banner/Banner";
import Header from "../../components/Header/Header";
import AboutService from "../../components/AboutService/AboutService";
import MetalPrices from "../../components/MetalPrices/MetalPrices";
import PictureWithButtons from "../../components/PictureWithButton/PictureWithButtons";
import InfoTable from "../../components/InfoTable/InfoTable";
import CallUs from "../../components/CallUs/CallUs";
import Services from "../../components/Services/Services";
import Footer from "../../components/Footer/Footer";
import {useDispatch, useSelector} from "react-redux";
import {setFormOpened} from "../../Redux/reducers/formReducer";

export default function SellMetalsPage() {
    let dispatch = useDispatch();
    let languages = useSelector(state => state.languages)



    return (
        <div className="sellMetalsPage">
            <Header color="gray"/>
            <Banner type={3}/>
            <AboutService type={3}/>
            <div className="sellMetalsPage__pricesBlock">
                <div className="container">
                    <h2>{languages.sellMetalsPageOurPrices}</h2>
                    <div className="sellMetalsPage__row">
                        <MetalPrices/>
                        <div className="sellMetalsPage__text">
                            {languages.sellMetalsWeSell}
                            <p className="mob">{languages.sellMetalsYouShouldnt}</p>
                            <div className="sellMetalsPage__buttonsRow">
                                <button onClick={() => {dispatch(setFormOpened())}} className="blueButton">{languages.sellMetalsBookOnline}</button>
                                <button onClick={() => {dispatch(setFormOpened())}} className="grayButton">{languages.sellMetalsBookCall}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PictureWithButtons type={2}/>
            <div className="sellMetalsPage__info">
                <div className="container">
                    {languages.sellMetalsPageWhenWeOffers}
                    <InfoTable/>
                </div>
            </div>
            <CallUs text={languages.sellMetalsPageCallUs}/>
            <Services page="sellMetals"/>
            <Footer/>
        </div>
    );
}