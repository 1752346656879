import React, { useState } from "react";
import { useSelector } from "react-redux";

import { validateEmail } from "helpers/validators";
import Spinner from "components/Spinner/Spinner";
import PurchaseDeliveryTypeField from "components/purchase/PurchaseDeliveryTypeField";
import PurchaseDeliveryCountryField from "components/purchase/PurchaseDeliveryCountryField";
import "./purchase.scss";

const PurchaseCustomerForm = ({ initForm, setFormHandler, isLoading, isError, totalPrice, setStep }) => {
    const { cart } = useSelector((state) => state.cart);
    const languages = useSelector((state) => state.languages);
    const prepay = totalPrice / 10 > 2000 ? 2000 : (totalPrice / 10).toFixed(2);
    const [form, setForm] = useState(initForm);
    const [incorrectFields, setIncorrectFields] = useState([]);
    const [deliveryType, setDeliveryType] = useState(initForm.deliveryType);
    const [deliveryCountry, setDeliveryCountry] = useState(initForm.deliveryCountry);
    const [deliveryAddress, setDeliveryAddress] = useState(initForm.deliveryAddress || "");
    const lang = languages.language;

    const formChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        if (incorrectFields.includes(name)) {
            const filteredIncorrectFields = incorrectFields.filter((item) => item !== name);
            setIncorrectFields(filteredIncorrectFields);
        }

        if (name === "phone") {
            if (value.length < 20) {
                let phoneValue = value;

                if (phoneValue.trim()) {
                    phoneValue = phoneValue.replace(/\D/g, "");

                    if (/^89/.test(phoneValue)) {
                        phoneValue = `7${phoneValue.slice(1)}`;
                    }

                    phoneValue = `+${phoneValue}`;
                }

                setForm({
                    ...form,
                    phone: phoneValue,
                });
            }
        } else {
            setForm({
                ...form,
                [name]: value,
            });
        }
    };

    const changeDeliveryType = (type) => {
        if (type !== deliveryType) {
            setDeliveryType(type);
        }
    };

    const changeDeliveryCountry = (country) => {
        if (country !== deliveryCountry) {
            setDeliveryCountry(country);
        }
    };

    const changeDeliveryAddress = (event) => {
        if (incorrectFields.includes("mail")) {
            const filteredIncorrectFields = incorrectFields.filter((item) => item !== "mail");
            setIncorrectFields(filteredIncorrectFields);
        }

        setDeliveryAddress(event.target.value);
    };

    const sendForm = () => {
        const errorFields = [];
        if (!form.name) errorFields.push("name");
        if (!form.lastName) errorFields.push("lastName");
        if (!validateEmail(form.email)) errorFields.push("email");
        if (!form.phone) errorFields.push("phone");
        if (deliveryType === "mail" && !deliveryAddress) errorFields.push("mail");

        if (!errorFields.length) {
            setFormHandler({
                ...form,
                deliveryType,
                deliveryAddress,
                deliveryCountry,
            });

            setStep(3);
        } else {
            setIncorrectFields(errorFields);
        }
    };

    return (
        <>
            {isLoading || isError ? (
                <div className="purchasePage__step3">
                    <div className="container">
                        <div className="purchasePage__confirmationBlock">
                            {isLoading && <Spinner />}
                            {isError && <div className="checkout-form__error">{languages.purchasePageError}</div>}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="purchasePage__step2">
                    <div className="container">
                        <div className="purchasePage__grayPlaceholder" />
                        <div className="purchasePage__infoRow">
                            <div className="purchasePage__infoBlock">
                                <h2>{languages.purchasePageInfoAbout}</h2>
                                <div className={`animatedInput ${incorrectFields.includes("lastName") ? "error" : ""}`}>
                                    <input
                                        value={form.lastName}
                                        placeholder=" "
                                        name="lastName"
                                        onChange={formChange}
                                        autoFocus
                                    />
                                    <label htmlFor="lastName">{languages.purchasePageLastName}</label>
                                </div>
                                <div className={`animatedInput ${incorrectFields.includes("name") ? "error" : ""}`}>
                                    <input value={form.name} placeholder=" " name="name" onChange={formChange} />
                                    <label htmlFor="name">{languages.purchasePageName}</label>
                                </div>
                                <div className={`animatedInput ${incorrectFields.includes("email") ? "error" : ""}`}>
                                    <input value={form.email} placeholder=" " name="email" onChange={formChange} />
                                    <label htmlFor="email">Email</label>
                                </div>
                                <div className={`animatedInput ${incorrectFields.includes("phone") ? "error" : ""}`}>
                                    <input
                                        value={form.phone}
                                        type="tel"
                                        inputMode="tel"
                                        placeholder=" "
                                        name="phone"
                                        onChange={formChange}
                                    />
                                    <label htmlFor="phone">{languages.callUsPhone}</label>
                                </div>

                                <PurchaseDeliveryTypeField
                                    type="storage"
                                    label={languages.purchasePageOurStorage}
                                    isChecked={deliveryType === "storage"}
                                    onClickHandler={changeDeliveryType}
                                />

                                {deliveryType === "storage" && (
                                    <PurchaseDeliveryCountryField
                                        countries={[
                                            {
                                                type: "cyprus",
                                                label: languages.purchasePageDeliveryCyprus,
                                            },
                                            {
                                                type: "dubai",
                                                label: languages.purchasePageDeliveryDubai,
                                            },
                                        ]}
                                        chosenCountry={deliveryCountry}
                                        onClickHandler={changeDeliveryCountry}
                                    />
                                )}

                                <PurchaseDeliveryTypeField
                                    type="mail"
                                    label={languages.purchasePageMail}
                                    isChecked={deliveryType === "mail"}
                                    onClickHandler={changeDeliveryType}
                                />

                                {deliveryType === "mail" && (
                                    <div
                                        style={{ marginTop: 10 }}
                                        className={`animatedInput ${incorrectFields.includes("mail") ? "error" : ""}`}
                                    >
                                        <input
                                            value={deliveryAddress}
                                            placeholder=" "
                                            name="mail"
                                            onChange={changeDeliveryAddress}
                                        />
                                        <label htmlFor="mail">{languages.purchasePageAddress}</label>
                                    </div>
                                )}

                                <div className="row">
                                    <button onClick={sendForm} className="blueButton">
                                        {languages.purchasePagePayPrepay}
                                    </button>
                                    {languages.purchasePageClickingButton}
                                </div>
                            </div>

                            <div className="purchasePage__infoCart">
                                <h2>
                                    {languages.purchasePageGoods} ({cart.length}{" "}
                                    {languages.purchasePageQuantityMeasurement}.)
                                </h2>

                                {cart.map((item) => {
                                    const price = item.price * item.quantity;

                                    return (
                                        <div className="purchasePage__infoCartCard" key={item.id}>
                                            <img alt="" src={item.image} />
                                            <div className="text">
                                                <div className="rowTitle">
                                                    <h3>{item.name[lang]}</h3>
                                                    <h3 className="thin">({item.quantity})</h3>
                                                </div>
                                                <div className="row">
                                                    <div className="price">
                                                        <label>{languages.purchasePagePrice}</label>
                                                        <h3>{price.toFixed(2)} $</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}

                                <h3 className="thin">{languages.purchasePageTotal}</h3>
                                <div className="row">
                                    <div className="bigPrice">
                                        <label>{languages.purchasePagePrepay}</label>
                                        <span>{prepay} $</span>
                                    </div>
                                    <div className="bigPrice">
                                        <label>{languages.purchasePageTotalSum}</label>
                                        <span>{totalPrice.toFixed(2)} $</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PurchaseCustomerForm;
