import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Banner from "../../components/Banner/Banner";
import AboutBlock from "./components/AboutBlock/AboutBlock";
import Calculator from "../../components/Calculator/Calculator";
import Safety from "../../components/Safety/Safety";
import WeSellMetals from "./components/WeSellMetals/WeSellMetals";
import Reviews from "../../components/Reviews/Reviews";
import CallUs from "../../components/CallUs/CallUs";
import FAQ from "../../components/FAQ/FAQ";
import Footer from "../../components/Footer/Footer";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export default function MainPage() {
    let languages = useSelector((state) => state.languages);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname === "/") {
            window.location.pathname = `/${languages.prefix}`;
        }
    }, []);

    return (
        <div id="mainPage">
            <Header color="gray" />
            <Banner />
            <AboutBlock />
            <Calculator />
            <Safety />
            <WeSellMetals />
            <Reviews />
            <FAQ />
            <CallUs text={languages.mainPageCallUs} />
            <Footer />
        </div>
    );
}
