import React, { useEffect, useState } from "react";
import arrowRight from "./imgs/arrowRight.svg";
import arrowLeft from "./imgs/arrowLeft.svg";
import "./Banner.scss";

import banner1 from "./imgs/banner1.png";
import banner2 from "./imgs/banner2.png";
import banner3 from "./imgs/banner3.png";
import banner4 from "./imgs/banner4.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setFormOpened } from "Redux/reducers/formReducer";

export default function Banner(props) {
  let languages = useSelector((state) => state.languages);

  let dispatch = useDispatch();
  let [block, setBlock] = useState(1);
  let navigate = useNavigate();
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe) {
      if (isLeftSwipe) {
        if (block !== 4) {
          setBlock(block + 1);
        }
      }
      if (isRightSwipe) {
        if (block !== 1) {
          setBlock(block - 1);
        }
      }
    }
  };

  function previousBlock() {
    if (block !== 1) {
      let el = document.getElementsByClassName("banner__block-" + block)[0];
      el.classList.remove("shown");
      setTimeout(() => {
        setBlock(block - 1);
      }, 150);
    }
  }

  function nextBlock() {
    if (block !== 4) {
      let el = document.getElementsByClassName("banner__block-" + block)[0];
      el.classList.remove("shown");
      setTimeout(() => {
        setBlock(block + 1);
      }, 150);
    }
  }
  useEffect(() => {
    setTimeout(() => {
      let el = document.getElementsByClassName("banner__block-" + block)[0];

      if (el) {
        el.classList.add("shown");
      }
    }, 150);
  }, [block]);
  if (props.type === 1) {
    return (
      <div className="banner">
        <div className="container">
          <div className="banner__block banner__block-1">
            <div className="banner__textBlock banner__textBlock-solo">
              {languages.bannerType1Title}
              {languages.bannerType1Text}
              <div className="banner__buttons">
                <button
                  onClick={() => {
                    dispatch(setFormOpened());
                  }}
                  className="blueButton"
                >
                  {languages.bannerLeaveRequest}
                </button>
              </div>
            </div>
            <img alt="" src={banner1} className="banner__image" />
          </div>
        </div>
      </div>
    );
  }
  if (props.type === 2) {
    return (
      <div className="banner">
        <div className="container">
          <div className="banner__block banner__block-2 banner__block-solo">
            <div className="banner__textBlock banner__textBlock-solo">
              {languages.bannerType2Title}
              <p>{languages.bannerType2Text}</p>
              <div className="banner__buttons">
                <button
                  onClick={() => {
                    dispatch(setFormOpened());
                  }}
                  className="blueButton"
                >
                  {languages.bannerLeaveRequest}
                </button>
              </div>
            </div>
            <img alt="" src={banner2} className="banner__image" />
          </div>
        </div>
      </div>
    );
  }
  if (props.type === 3) {
    return (
      <div className="banner">
        <div className="container">
          <div className="banner__block banner__block-3 banner__block-solo">
            <div className="banner__textBlock banner__textBlock-solo">
              {languages.bannerType3Title}
              <p>{languages.bannerType3Text}</p>
              <div className="banner__buttons">
                <button
                  onClick={() => {
                    navigate("/buy");
                  }}
                  className="blueButton"
                >
                  {languages.bannerType3OrderOnline}
                </button>
                <button
                  onClick={() => {
                    dispatch(setFormOpened());
                  }}
                  className="grayButton"
                >
                  {languages.bannerType3OrderCall}
                </button>
              </div>
            </div>
            <img alt="" src={banner3} className="banner__image" />
          </div>
        </div>
      </div>
    );
  }
  if (props.type === 4) {
    return (
      <div className="banner">
        <div className="container">
          <div className="banner__block banner__block-4 banner__block-solo">
            <div className="banner__textBlock">
              {languages.bannerType4Title}
              <p>{languages.bannerType4Text}</p>
              <div className="banner__buttons">
                <button
                  onClick={() => {
                    dispatch(setFormOpened());
                  }}
                  className="blueButton"
                >
                  {languages.bannerLeaveRequest}
                </button>
              </div>
            </div>
            <img alt="" src={banner4} className="banner__image" />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="banner">
      <div
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        className="container"
      >
        {block === 1 ? (
          <div className="banner__block banner__block-1">
            <div className="banner__textBlock">
              {languages.bannerType1Title}
              {languages.bannerType1Text}
              <div className="banner__buttons">
                <button
                  onClick={() => {
                    dispatch(setFormOpened());
                  }}
                  className="blueButton"
                >
                  {languages.bannerLeaveRequest}
                </button>
                <button
                  onClick={() => {
                    navigate("storeMetals");
                  }}
                  className="grayButton"
                >
                  {languages.bannerLearnMore}
                </button>
              </div>
            </div>
            <img alt="" src={banner1} className="banner__image" />
          </div>
        ) : (
          ""
        )}
        {block === 2 ? (
          <div className="banner__block banner__block-2">
            <div className="banner__textBlock">
              {languages.bannerType2Title}
              <p>{languages.bannerType2Text}</p>
              <div className="banner__buttons">
                <button
                  onClick={() => {
                    dispatch(setFormOpened());
                  }}
                  className="blueButton"
                >
                  {languages.bannerLeaveRequest}
                </button>
                <button
                  onClick={() => {
                    navigate("storeMoney");
                  }}
                  className="grayButton"
                >
                  {languages.bannerLearnMore}
                </button>
              </div>
            </div>
            <img alt="" src={banner2} className="banner__image" />
          </div>
        ) : (
          ""
        )}
        {block === 3 ? (
          <div className="banner__block banner__block-3">
            <div className="banner__textBlock">
              {languages.bannerBlock3Title}
              <p>{languages.bannerType3Text}</p>
              <div className="banner__buttons">
                <button
                  onClick={() => {
                    navigate("/buy");
                  }}
                  className="blueButton"
                >
                  {languages.bannerType3OrderOnline}
                </button>
                <button
                  onClick={() => {
                    navigate("sellMetals");
                  }}
                  className="grayButton"
                >
                  {languages.bannerLearnMore}
                </button>
              </div>
            </div>
            <img alt="" src={banner3} className="banner__image" />
          </div>
        ) : (
          ""
        )}
        {block === 4 ? (
          <div className="banner__block banner__block-4">
            <div className="banner__textBlock">
              {languages.bannerBlock2Title}
              <p>{languages.bannerType4Text}</p>
              <div className="banner__buttons">
                <button
                  onClick={() => {
                    dispatch(setFormOpened());
                  }}
                  className="blueButton"
                >
                  {languages.bannerLeaveRequest}
                </button>
                <button
                  onClick={() => {
                    navigate("buyMetals");
                  }}
                  className="grayButton"
                >
                  {languages.bannerLearnMore}
                </button>
              </div>
            </div>
            <img alt="" src={banner4} className="banner__image" />
          </div>
        ) : (
          ""
        )}
        <div className="banner__slider">
          <div className="banner__sliderRow">
            <div className={block === 1 ? "dot active" : "dot"} />
            <div className={block === 2 ? "dot active" : "dot"} />
            <div className={block === 3 ? "dot active" : "dot"} />
            <div className={block === 4 ? "dot active" : "dot"} />
          </div>
        </div>
        <div className="banner__controls">
          <p>
            <span>{block}/</span>4
          </p>
          <div className="banner__controlButtons">
            <button className="roundButton-light" onClick={previousBlock}>
              <img alt="" src={arrowLeft} />
            </button>
            <button className="roundButton-light" onClick={nextBlock}>
              <img alt="" src={arrowRight} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
