import React from 'react';
import './ServicesPage.scss';
import Header from "../../components/Header/Header";
import Services from "../../components/Services/Services";
import OtherOffers from "../../components/OtherOffers/OtherOffers";
import Footer from "../../components/Footer/Footer";
import {useSelector} from "react-redux";

export default function ServicesPage() {
    let languages = useSelector(state => state.languages)

    return (
        <div className="servicesPage">
            <Header/>
            <div className="container servicesPage__services">
                <div className="servicesPage__content">
                    <div className="container">
                        <label>{languages.headerServices}</label>
                    </div>
                    <h2>{languages.servicesPageMain}</h2>
                    <Services type="all"/>
                </div>
            </div>
            <div className="container servicesPage__winnings">
                <div className="servicesPage__winningsRow">
                    <h2>{languages.servicesPageWinnings}</h2>
                    <p>{languages.servicesPageWinningsText}</p>
                </div>
            </div>
            <OtherOffers/>
            <Footer/>
        </div>
    );
}