import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "./imgs/logo.svg";
import "./Header.scss";
import bars from "./imgs/bars.svg";
import close from "./imgs/close.svg";
import CallUs from "../CallUs/CallUs";
import { useDispatch, useSelector } from "react-redux";
import { setFormClosed, setFormOpened } from "../../Redux/reducers/formReducer";
import { setChinese, setEnglish, setRussian } from "../../Redux/reducers/languagesReducer";
import { changeLanguageInURL } from "../../helpers/utilFunctions";
import SwitchLanguage from "./SwitchLanguage";

export default function Header(props) {
    const [isTabMenuOpened, setIsTabMenuOpened] = useState(false);
    let formState = useSelector((state) => state.form);
    let languages = useSelector((state) => state.languages);

    let dispatch = useDispatch();

    const { prefix } = languages;

    function openTabMenu(event) {
        setIsTabMenuOpened(true);
    }

    function closeTabMenu(event) {
        setIsTabMenuOpened(false);
    }
    function openForm() {
        dispatch(setFormOpened());
        setIsTabMenuOpened(false);
    }
    function closeForm() {
        dispatch(setFormClosed());
    }

    function switchToRussian() {
        if (languages.language !== "RU") {
            dispatch(setRussian());
            changeLanguageInURL("ru");
        }
    }

    function switchToEnglish() {
        if (languages.language !== "ENG") {
            dispatch(setEnglish());
            changeLanguageInURL("en");
        }
    }

    function switchToChinese() {
        if (languages.language !== "CHN") {
            dispatch(setChinese());
            changeLanguageInURL("cn");
        }
    }

    return (
        <div className={props.color === "gray" ? "header header__gray" : "header"}>
            <div className="header__firstRow">
                <div className="container">
                    <Link to="/">
                        <img className="header__logo" alt="" src={logo} />
                    </Link>
                    <div className="header__links">
                        <Link to={`/${prefix}/about`}>{languages.headerAbout}</Link>
                        <Link to={`/${prefix}/contacts`}>{languages.headerContacts}</Link>
                    </div>
                    <div className="header__links header__links-tab">
                        <Link to={`/${prefix}/services`}>{languages.headerServices}</Link>
                        <Link to={`/${prefix}/storage`}>{languages.headerStorage}</Link>
                    </div>
                    <SwitchLanguage />
                    <div className="header__languages">
                        <button onClick={switchToRussian} className={languages.language === "RU" ? "active" : ""}>
                            RU
                        </button>
                        <button onClick={switchToEnglish} className={languages.language === "ENG" ? "active" : ""}>
                            ENG
                        </button>
                        <button onClick={switchToChinese} className={languages.language === "CHN" ? "active" : ""}>
                            CHN
                        </button>
                        <img src={bars} alt="" onClick={openTabMenu} />
                    </div>
                    <div className="header__language-switch tablet"></div>
                    <button onClick={openForm} className="header__becameClient">
                        {languages.headerBecame}
                    </button>
                </div>
            </div>
            <div className="header__secondRow">
                <div className="container">
                    <div className="header__bottomLinks">
                        <Link to={`/${prefix}/services`}>{languages.headerServices}</Link>
                        <Link to={`/${prefix}/storage`}>{languages.headerStorage}</Link>
                    </div>
                    <div className="header__support">
                        <Link to={`/${prefix}/support`}>{languages.headerSupport}</Link>
                    </div>
                </div>
            </div>
            <div className={isTabMenuOpened ? "header__tabMenu opened" : "header__tabMenu"}>
                <div className="container container__tabMenu">
                    <Link to="/">
                        <img className="header__logo" alt="" src={logo} />
                    </Link>
                    <button onClick={closeTabMenu}>
                        <img alt="" src={close} />
                    </button>
                </div>
                <div className="header__tabMenuHolder container">
                    <div className="header__tabMenuLinks">
                        <Link to={`/${prefix}/about`}>{languages.headerAbout}</Link>
                        <Link to={`/${prefix}/contacts`}>{languages.headerContacts}</Link>
                        <Link to={`/${prefix}/services`}>{languages.headerServices}</Link>
                        <Link to={`/${prefix}/storage`}>{languages.headerStorage}</Link>
                        <Link to={`/${prefix}/support`}>{languages.headerSupport}</Link>
                    </div>
                    <button onClick={openForm} className="blueButton">
                        {languages.headerSupport}
                    </button>
                </div>
            </div>
            <div className={formState.isFormOpened ? "header__form opened" : "header__form"}>
                <CallUs type={3} text={languages.formStartInvesting} />
                <img style={{ cursor: "pointer" }} onClick={closeForm} src={close} />
            </div>
        </div>
    );
}
