import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { DevSupport } from "@react-buddy/ide-toolbox";
import { ComponentPreviews, useInitial } from "./dev";
import SetPreferredLanguageFromURL from "./hoc/SetPreferredLanguageFromURL";
import { BrowserRouter, useLocation } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./Redux/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}
root.render(
    // <React.StrictMode>
    <>
        <DevSupport ComponentPreviews={ComponentPreviews} useInitialHook={useInitial}>
            <BrowserRouter>
                <ScrollToTop />
                <Provider store={store}>
                    <App />
                </Provider>
            </BrowserRouter>
        </DevSupport>
    </>
    // </React.StrictMode>
    // TODO ВЕРНУТЬ
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
