import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { API_URL, PUBLISHABLE_KEY } from "config";
import PurchaseCheckoutForm from "components/purchase/PurchaseCheckoutForm";
import Spinner from "components/Spinner/Spinner";

const stripePromise = loadStripe(PUBLISHABLE_KEY);

const Stripe = ({ email, totalPrice, languages, setSuccessfulPayment }) => {
    const [clientSecret, setClientSecret] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        const prepay = totalPrice / 10 > 2000 ? 2000 : (totalPrice / 10).toFixed(2);
        const currency = "usd";

        if (prepay) {
            const amount = Math.round(prepay * 100);

            fetch(`${API_URL}/paymentIntent.php?amount=${amount}&currency=${currency}&email=${email}`)
                .then((response) => response.json())
                .then((data) => {
                    if (data?.client_secret) {
                        setClientSecret(data.client_secret);
                    } else {
                        setError(true);
                    }
                })
                .catch(() => {
                    setError(true);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [email, totalPrice]);

    return (
        <>
            {isLoading && <Spinner />}

            {error ? (
                <div className="checkout-form__error">{languages.purchasePageError}</div>
            ) : stripePromise && clientSecret ? (
                <Elements stripe={stripePromise}>
                    <PurchaseCheckoutForm
                        clientSecret={clientSecret}
                        setSuccessfulPayment={setSuccessfulPayment}
                        languages={languages}
                    />
                </Elements>
            ) : null}
        </>
    );
};

export default Stripe;
