import React from 'react';
import './PictureWithText.scss';

import buyMetalsImg from './imgs/buyMetalsImg.png';
import basel3Img from './imgs/basel3Img.png';
import {useSelector} from "react-redux";

export default function PictureWithText(props) {
    let languages = useSelector(state => state.languages)
    if(props.type === 2){
        return (
            <div className="pictureWithText pictureWithText-2">
                <div className="container">
                    <div className="pictureWithText__text pictureWithText__text-2">
                        <h2>{languages.pictureWithTextAboutService}</h2>
                        {languages.pictureWithTextSince2023}
                        <div className="pictureWithText__rowsHolder">
                            <div className="pictureWithText__row">
                                <div className="line"/>
                                {languages.pictureWithTextWouldBePossible}
                            </div>
                            <div className="pictureWithText__row">
                                <div className="line"/>
                                <h3>{languages.pictureWithTextMaybeGrow}</h3>
                            </div>
                        </div>
                    </div>
                    <img className="pictureWithText__img" src={basel3Img} alt=""/>
                </div>
            </div>
        );
    }
    return (
        <div className="pictureWithText">
            <div className="container">
                <div className="pictureWithText__text">
                    <h2>{languages.pictureWithTextAboutService}</h2>
                    {languages.pictureWithTextWeHelp}
                </div>
                <img className="pictureWithText__img" src={buyMetalsImg} alt=""/>
            </div>
        </div>
    );
}