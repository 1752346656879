let SET_FORM_OPENED = "SET_FORM_OPENED";
let SET_FORM_CLOSED = "SET_FORM_CLOSED";

let defaultState = {
    isFormOpened: false
}
export default function formReducer(state = defaultState, action){
    switch (action.type){
        case SET_FORM_OPENED:
            return{
                ...state,
                isFormOpened: true
            }
        case SET_FORM_CLOSED:
            return{
                ...state,
                isFormOpened: false
            }
        default:
            return state
    }
}

export const setFormOpened = () => ({type: SET_FORM_OPENED})
export const setFormClosed = () => ({type: SET_FORM_CLOSED})