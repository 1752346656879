import React, { useState } from "react";
import "./AboutBlock.scss";

import milli from "./imgs/milli.png";
import vk from "./imgs/vk.png";
import lloyds from "./imgs/Lloyds.svg";
import map from "./imgs/map.svg";
import arrowLeft from "./imgs/arrowLeft.svg";
import arrowRight from "./imgs/arrowRight.svg";
import smallArrowRight from "../../../../imgs/smallArrowRight.svg";
import InfoTable from "../../../../components/InfoTable/InfoTable";
import Services from "../../../../components/Services/Services";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function AboutBlock() {
    let languages = useSelector((state) => state.languages);
    const [isOnStart, setIsOnStart] = useState(true);
    const [isOnEnd, setIsOnEnd] = useState(false);

    function onStart() {
        setIsOnStart(!isOnStart);
    }

    function onEnd() {
        setIsOnEnd(!isOnEnd);
    }

    let navigate = useNavigate();
    return (
        <div className="aboutBlock">
            <div className="container">
                <h3 className="thin">{languages.headerAbout}</h3>
                {languages.mainPageWeStore}
                <div className="aboutBlock__partnersRow">
                    <img
                        onClick={() => {
                            window.open("https://millionaire-international.ru", "_blank");
                        }}
                        alt=""
                        src={milli}
                    />
                    <img
                        onClick={() => {
                            window.open("https://www.lloyds.com", "_blank");
                        }}
                        alt=""
                        src={lloyds}
                    />
                    {languages.language === "RU" && (
                        <img
                            onClick={() => {
                                window.open(
                                    "https://vkcyprus.com/press-release/16007-vash-kapital-pod-nadezhnoj-zashchitoj",
                                    "_blank"
                                );
                            }}
                            alt=""
                            src={vk}
                        />
                    )}
                </div>
                <div className="aboutBlock__mapRow">
                    <div className="text">
                        {languages.mainPageInternational}
                        <button
                            onClick={() => {
                                navigate("/about");
                            }}
                            className="arrowButton"
                        >
                            <label>{languages.bannerLearnMore}</label>
                            <img alt="" src={smallArrowRight} />
                        </button>
                    </div>
                    <img className="aboutBlock__map" src={map} alt="" />
                </div>
                <InfoTable />
            </div>
            <div className="aboutBlock__services">
                <div className="container">
                    <div className="aboutBlock__servicesRow">
                        <h2>{languages.mainPageServices}</h2>
                        {languages.mainPageServicesUsedBy}
                    </div>
                    <Services isOnEnd={isOnEnd} isOnStart={isOnStart} />
                    <div className="aboutBlock__buttonsRow">
                        <button
                            onClick={() => {
                                navigate("/services");
                            }}
                            className="blueButton"
                        >
                            {languages.mainPageSeeAll}
                        </button>
                        <div className="aboutBlock__controlsRow">
                            <button className="roundButton-light" onClick={onStart}>
                                <img alt="" src={arrowLeft} />
                            </button>
                            <button className="roundButton-light" onClick={onEnd}>
                                <img alt="" src={arrowRight} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
