export const validatePhone = (phone) => {
  const regex = /^\+[0-9] \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/im;

  return regex.test(phone)
}

export const validateEmail = (email) => {
  const regex = /\S+@\S+\.\S+/im;

  return regex.test(email)
}