import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { changeQuantityInCart, hideCart, removeFromCart } from "Redux/reducers/cartReducer";
import plus from "../../pages/PurchasePage/imgs/plus.svg";
import minus from "../../pages/PurchasePage/imgs/minus.svg";
import close from "./imgs/Vector.svg";
import "./Cart.scss";

export default function Cart({ nextStep, totalPrice }) {
    const dispatch = useDispatch();
    const { cart } = useSelector((state) => state.cart);
    const languages = useSelector((state) => state.languages);
    const prepay = totalPrice / 10 > 2000 ? 2000 : (totalPrice / 10).toFixed(2);
    const lang = languages.language;

    function addQuantity(item) {
        item.quantity++;
        dispatch(changeQuantityInCart(item));
    }

    function reduceQuantity(item) {
        item.quantity--;
        dispatch(changeQuantityInCart(item));
    }

    function changeQuantity(e, item) {
        item.quantity = e.target.value;
        dispatch(changeQuantityInCart(item));
    }

    function blurItemHandler(e, item) {
        if (e.target.value === "") {
            dispatch(removeFromCart(item));
        }
    }

    function removeItemHandler(item) {
        dispatch(removeFromCart(item));
    }

    const nextBtnHandler = () => {
        if (totalPrice) {
            dispatch(hideCart());
            nextStep();
        }
    };

    const closeCartHandler = () => {
        dispatch(hideCart());
    };

    return (
        <div className="cart">
            <div onClick={closeCartHandler} className="cart__close">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                        fill="black"
                    />
                </svg>
            </div>
            <div className="cart__inner">
                <div className="cart__inner-flex">
                    <div className="cart__inner-flex-content">
                        <h2>
                            {languages.purchasePageCart} ({cart.length} {languages.purchasePageAfterCartGoods})
                        </h2>

                        {cart.map((item) => {
                            const price = item.price * item.quantity;

                            return (
                                <div className="cart__item" key={item.id}>
                                    <div className="rowText">
                                        <h3>{item.name[lang]}</h3>
                                        <button onClick={() => removeItemHandler(item)}>
                                            <img src={close} alt="" />
                                        </button>
                                    </div>
                                    <div className="rowControls">
                                        <div className="quantity">
                                            <label>{languages.purchasePageQuantity}</label>
                                            <div className="purchasePage__quantity">
                                                <img
                                                    onClick={() => {
                                                        reduceQuantity(item);
                                                    }}
                                                    className="minus"
                                                    alt=""
                                                    src={minus}
                                                />
                                                <input
                                                    onChange={(e) => {
                                                        changeQuantity(e, item);
                                                    }}
                                                    onBlur={(e) => {
                                                        blurItemHandler(e, item);
                                                    }}
                                                    min="0"
                                                    type="number"
                                                    value={item.quantity}
                                                />
                                                <img
                                                    onClick={() => {
                                                        addQuantity(item);
                                                    }}
                                                    className="plus"
                                                    src={plus}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                        <div className="itemPriceRow">
                                            <div className="price">
                                                <label>{languages.purchasePagePrice}</label>
                                                <h4>{price.toFixed(2)} $</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    <div>
                        <h3 className="thin">{languages.purchasePageTotal}</h3>
                        <div className="rowPrices">
                            <div className="bigPrice">
                                <label>{languages.purchasePagePrepay}</label>
                                <span>{prepay} $</span>
                            </div>
                            <div className="bigPrice totalPrice">
                                <label>{languages.purchasePageTotalSum}</label>
                                <span>{totalPrice.toFixed(2)} $</span>
                            </div>
                        </div>
                        <div className="cart__button-next">
                            <button onClick={nextBtnHandler} className={`blueButton ${!totalPrice ? "disabled" : ""}`}>
                                {languages.purchasePageNext}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
