import React, {useEffect, useState} from 'react';
import './Calculator.scss';

import info from '../../imgs/info.svg';
import {useDispatch, useSelector} from "react-redux";
import {setFormOpened} from "../../Redux/reducers/formReducer";
import {Link} from "react-router-dom";

export default function Calculator() {
    let languages = useSelector(state => state.languages)
    let dispatch = useDispatch();

    const [price, setPrice] = useState('');
    const [time, setTime] = useState('');
    const [economy, setEconomy] = useState('');


    function calculateEconomy(){
        if(parseFloat(price) >= 0 && parseFloat(time) >= 0){
            let nds = 0.19 * parseFloat(price);
            let storagePrice = 0.01 * parseFloat(price);
            setEconomy(((nds - storagePrice) * parseInt(time)).toFixed(2))
        } else {
            setEconomy('0.00')
        }
    }

    useEffect(() => {
        calculateEconomy()
    }, [price, time]);
    return(
        <div className="calculator">
            <div className="container">
                <div className="calculator__content">
                    <h2>{languages.calculatorCalculateYour}</h2>
                    <div className="calculator__row">
                        {languages.calculatorImportExport}
                        <div className="calculator__block">
                            <div className="calculator__fieldsRow">
                                <div className="animatedInput">
                                    <p>€</p>
                                    <div className="info">
                                        <img src={info}/>
                                    </div>
                                    <input value={price} onChange={e => {
                                        setPrice(e.currentTarget.value);
                                    }} placeholder="" id="price"/>
                                    <label htmlFor="price">{languages.calculatorCalculatedPrice}</label>
                                </div>
                                <div className="animatedInput">
                                    <p>Лет</p>
                                    <div className="info">
                                        <img src={info}/>
                                    </div>
                                    <input value={time} onChange={e => {
                                        setTime(e.currentTarget.value);
                                    }} placeholder="" id="storeTime"/>
                                    <label htmlFor="storeTime">{languages.calculatorTimeStore}</label>
                                </div>
                            </div>
                            <div className="calculator__priceRow">
                                <label>{languages.calculatorWinning}</label>
                                <img alt="" src={info}/>
                            </div>
                            <span>{economy} €</span>
                            <div className="calculator__buttonsRow">
                                <button onClick={() => {dispatch(setFormOpened())}} className="blueButton">{languages.calculatorLeaveReqOnStore}</button>
                                <Link to='/storeMetals' className="grayButton">{languages.bannerLearnMore}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}