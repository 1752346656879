import React from "react";

import Checkbox from "components/Svg/Checkbox";

const PurchaseDeliveryCountryField = ({ countries, chosenCountry, onClickHandler }) => {
    return (
        <div className="delivery-countries">
            {countries.map(({ type, label }) => {
                const isChecked = type === chosenCountry ? "checked" : "";

                return (
                    <div
                        className="animatedCheckbox"
                        onClick={() => {
                            onClickHandler(type);
                        }}
                        key={type}
                    >
                        <div className={`checkbox checkbox-round ${isChecked}`}>
                            <Checkbox />
                        </div>
                        <p>{label}</p>
                    </div>
                );
            })}
        </div>
    );
};

export default PurchaseDeliveryCountryField;
