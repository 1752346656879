import { setChinese, setEnglish } from "Redux/reducers/languagesReducer";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

const SetPreferredLanguageFromURL = () => {
    const dispatch = useDispatch();

    const extractPreferredLanguageFromURL = () => {
        return window.location.pathname.split("/")[1];
    };

    const setLanguage = () => {
        const preferredLanguage = extractPreferredLanguageFromURL();

        switch (preferredLanguage) {
            case "en": {
                dispatch(setEnglish());

                break;
            }

            case "cn": {
                dispatch(setChinese());

                break;
            }

            default: {
                return;
            }
        }
    };

    useEffect(setLanguage, []);

    return null;
};

export default SetPreferredLanguageFromURL;
