import { combineReducers } from "redux";
import { applyMiddleware } from "redux";
import { legacy_createStore as createStore } from "redux";
import thunk from "redux-thunk";

import { composeWithDevTools } from "@redux-devtools/extension";
import formReducer from "./reducers/formReducer";
import languagesReducer from "./reducers/languagesReducer";
import appReducer from "./reducers/appReducer";
import goodsReducer from "./reducers/goodsReducer";
import cartReducer from "Redux/reducers/cartReducer";
import metalsReducer from "./reducers/metalsReducer";

const rootReducer = combineReducers({
    form: formReducer,
    languages: languagesReducer,
    app: appReducer,
    goods: goodsReducer,
    metals: metalsReducer,
    cart: cartReducer,
});

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
