import React, {useEffect, useState} from 'react';
import './Reviews.scss';

import arrowRight from './imgs/arrowRight.svg';
import arrowLeft from './imgs/arrowLeft.svg';
import {useSelector} from "react-redux";

export default function Reviews() {
    let languages = useSelector(state => state.languages)
    const [review, setReview] = useState(1);
    function nextReview() {
        if(review !== 3){
            let el = document.getElementsByClassName('reviews__review-' + review)[0];
            el.classList.remove('shown');
            setTimeout(() => {
                setReview(review + 1);
            },150)
        }
    }

    function previousReview() {
        if(review !== 1){
            let el = document.getElementsByClassName('reviews__review-' + review)[0];
            el.classList.remove('shown');
            setTimeout(() => {
                setReview(review - 1);
            },150)
        }
    }

    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)

    const minSwipeDistance = 50

    const onTouchStart = (e) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if (isLeftSwipe || isRightSwipe) {
            if(isLeftSwipe){
                nextReview()
            }
            if(isRightSwipe){
                previousReview()
            }
        }
    }
    useEffect(() => {
        setTimeout(() => {
            let el = document.getElementsByClassName('reviews__review-' + review)[0];
            el.classList.add('shown');
        }, 150)
    }, [review]);
    return (
        <div className="reviews">
            <div className="container">
                <h2>{languages.reviews}</h2>
                <div onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} className="reviews__content">
                    {review === 1 ?
                        languages.reviewsReview1
                        : ''}
                    {review === 2 ?
                        languages.reviewsReview2
                        : ''}
                    {review === 3 ?
                        languages.reviewsReview3
                        : ''}
                </div>
                {languages.reviewsWeMustHide}
                <div className="reviews__counter">
                    <p><span>{review}/</span>3</p>
                    <div className="reviews__controls">
                        <button className="roundButton-light" onClick={previousReview}>
                            <img alt="" src={arrowLeft}/>
                        </button>
                        <button className="roundButton-light" onClick={nextReview}>
                            <img alt="" src={arrowRight}/>
                        </button>
                    </div>
                </div>
                <div className="reviews__paging">
                    <div className="dots">
                        <div className={review === 1 ? "dot active" : "dot"}/>
                        <div className={review === 2 ? "dot active" : "dot"}/>
                        <div className={review === 3 ? "dot active" : "dot"}/>
                    </div>
                </div>
            </div>
        </div>
    );
}