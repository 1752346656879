const SET_POPUP_SHOW = "SET_POPUP_SHOW";
const SET_POPUP_HIDE = "SET_POPUP_HIDE";

const defaultState = {
    popup: null,
};

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_POPUP_SHOW:
            return {
                ...state,
                popup: action.payload,
            };
        case SET_POPUP_HIDE:
            return {
                ...state,
                popup: null,
            };
        default:
            return state;
    }
}

export const setPopupShow = (text) => ({ type: SET_POPUP_SHOW, payload: text });
export const setPopupHide = () => ({ type: SET_POPUP_HIDE });
