import React from 'react';
import './AboutPage.scss';
import Header from "../../components/Header/Header";
import OtherBanner from "../../components/OtherBanner/OtherBanner";
import smallArrowRight from "../../imgs/smallArrowRight.svg";

import map from './imgs/map.png'
import ceoImg from './imgs/ceoImg.png'
import forbes from './imgs/forbes.svg'
import lloyds from './imgs/lloyds.svg'
import bcs from './imgs/bcs.svg'
import Reviews from "../../components/Reviews/Reviews";
import Documents from "../../components/Documents/Documents";
import Footer from "../../components/Footer/Footer";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import milli from "../MainPage/components/AboutBlock/imgs/milli.png";
import vk from "../MainPage/components/AboutBlock/imgs/vk.png";

export default function AboutPage() {
    let navigate = useNavigate();
    let languages = useSelector(state => state.languages)
    return (
        <div className="aboutPage">
            <Header/>
            <OtherBanner page="AboutPage"/>
            <div className="aboutPage__location">
                <div className="container">
                    <div className="aboutPage__row">
                        <h2>{languages.aboutPageLocation}</h2>
                        <div className="aboutPage__text">
                            <p>{languages.aboutPageLocationText}</p>
                            <button onClick={() => {navigate('/contacts')}} className="arrowButton">
                                <label>{languages.aboutPageGoContacts}</label>
                                <img alt="" src={smallArrowRight}/>
                            </button>
                        </div>
                    </div>
                    <img className="aboutPage__map" alt="" src={map}/>
                </div>
            </div>
            <div className="aboutPage__ceo">
                <div className="container">
                    <div className="aboutPage__ceoText">
                        <p>{languages.aboutPageCeoText}</p>
                        <h3>{languages.aboutPageCeoName}</h3>
                        <label>CEO</label>
                    </div>
                    <img className="aboutPage__ceoImg" alt="" src={ceoImg}/>
                </div>
            </div>
            <div className="aboutPage__partners">
                <div className="container">
                    <h3 className="thin">{languages.aboutPagePartners}</h3>
                    <h2>{languages.aboutPagePartnersText}</h2>
                    <div className="aboutPage__partnersRow">
                        <div onClick={() => {window.location.href='http://millionaire-international.ru'}} className="aboutPage__partner">
                            <img src={milli} alt=""/>
                        </div>
                        <div onClick={() => {window.location.href='https://www.lloyds.com'}} className="aboutPage__partner">
                            <img src={lloyds} alt=""/>
                        </div>
                        {languages.language !== 'CHN' && languages.language !== 'ENG' ?
                            <div onClick={() => {window.location.href='https://vkcyprus.com/press-release/16007-vash-kapital-pod-nadezhnoj-zashchitoj'}} className="aboutPage__partner">
                                <img src={vk} alt=""/>
                            </div>
                            : ''}
                    </div>
                </div>
            </div>
            <Reviews/>
            <Documents/>
            <Footer/>
        </div>
    );
}