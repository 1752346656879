import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "config";
import { setFormClosed } from "Redux/reducers/formReducer";
import Spinner from "components/Spinner/Spinner";
import { submitRequest } from "helpers/services/amocrm";
import inst from "./imgs/instagram.svg";
import fb from "./imgs/facebook.svg";
import linked from "./imgs/linked.svg";
import checkBig from "pages/PurchasePage/imgs/checkRoundBig.svg";
import "./CallUs.scss";

export default function CallUs(props) {
    const dispatch = useDispatch();
    const languages = useSelector((state) => state.languages);
    const [phoneInput, setPhoneInput] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isPhoneSent, setIsPhoneSent] = useState(false);
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const [isError, setIsError] = useState(false);

    const changeInputHandler = (e) => {
        let phoneValue = e.target.value;

        if (!isPhoneValid) {
            setIsPhoneValid(true);
        }

        if (isError) {
            setIsError(false);
        }

        if (phoneValue.length < 20) {
            if (phoneValue.trim()) {
                phoneValue = phoneValue.replace(/\D/g, "");

                if (/^89/.test(phoneValue)) {
                    phoneValue = `7${phoneValue.slice(1)}`;
                }

                phoneValue = `+${phoneValue}`;
            }

            setPhoneInput(phoneValue);
        }
    };

    const sendFeedbackToGoogleSheets = async (phone) => {
        if (!phoneInput.trim()) {
            setIsPhoneValid(false);

            return;
        }
        try {
            setIsLoading(true);

            const date = new Date();

            const response = await fetch(`${API_URL}/sendFeedbackToGoogleSheets.php`, {
                method: "POST",
                body: JSON.stringify({
                    phone,
                    date: `${date.getDate()}/${
                        date.getMonth() + 1
                    }/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
                }),
            });

            if (response.ok) {
                setIsPhoneSent(true);
            } else {
                setIsError(true);
            }

            setIsLoading(false);
        } catch (err) {
            console.log(err);

            return null;
        }
    };

    const clearForm = () => {
        setPhoneInput("");
        setIsPhoneSent(false);
    };

    const closeForm = () => {
        clearForm();
        dispatch(setFormClosed());
    };

    if (props.type === 3) {
        return (
            <div className="callUs">
                <div className="container">
                    <div className="callUs__content callUs__content-2">
                        {isPhoneSent ? (
                            <div className="callUs__submitMessage-2">
                                <div className="callUs__content-sentTitle">
                                    <h2>
                                        {languages.callUsPhoneSentTitle} <img src={checkBig} alt="" />
                                    </h2>
                                </div>
                                <p>
                                    {languages.callUsPhoneSentMessage} {phoneInput}
                                </p>

                                <div className="callUs__submitRow">
                                    <button className="blueButton" onClick={closeForm}>
                                        {languages.callUsPhoneSentOk}
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <>
                                {props.text}
                                {languages.callUsType3AnyQuestions}

                                {isLoading ? (
                                    <div className="callUs__spinner">
                                        <Spinner />
                                    </div>
                                ) : (
                                    <>
                                        <div className={`animatedInput ${!isPhoneValid ? "error" : ""}`}>
                                            <input
                                                id="phone3"
                                                value={phoneInput}
                                                className="phoneMask"
                                                type="tel"
                                                inputMode="tel"
                                                placeholder=" "
                                                name="phone"
                                                onChange={changeInputHandler}
                                            />
                                            <label htmlFor="phone3">{languages.callUsMobPhone}</label>
                                        </div>
                                        <div className="callUs__submitRow">
                                            <button
                                                className="blueButton"
                                                onClick={() => sendFeedbackToGoogleSheets(phoneInput)}
                                            >
                                                {languages.callUsSend}
                                            </button>
                                            {languages.callUsClickingYou}
                                        </div>
                                    </>
                                )}
                            </>
                        )}

                        {isError && <h4 className="callUs__error">{languages.purchasePageError}</h4>}
                    </div>
                    <div className="callUs__contacts">
                        <span>{languages.callUsYouSelf}</span>
                        <div className="rowContacts">
                            <div className="callUs__contact">
                                <label>{languages.callUsPhone}</label>
                                <p>+35770070117</p>
                            </div>
                            <div className="callUs__contact">
                                <label>Email</label>
                                <p>info@swiftglobal.cy</p>
                            </div>
                        </div>
                        <div className="callUs__links">
                            <button className="roundButton-light">
                                <img alt="" src={inst} />
                            </button>
                            <button className="roundButton-light">
                                <img alt="" src={fb} />
                            </button>
                            <button className="roundButton-light">
                                <img alt="" src={linked} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (props.type === 2) {
        return (
            <div className="callUs">
                <div className="container">
                    <div className="callUs__content callUs__content-2">
                        {isPhoneSent ? (
                            <>
                                <div className="callUs__content-sentTitle">
                                    <h2>
                                        {languages.callUsPhoneSentTitle} <img src={checkBig} alt="" />
                                    </h2>
                                </div>
                                <p>
                                    {languages.callUsPhoneSentMessage} {phoneInput}
                                </p>

                                <div className="callUs__submitRow">
                                    <button className="blueButton" onClick={clearForm}>
                                        {languages.callUsPhoneSentOk}
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>
                                {props.text}
                                <p>{languages.callUsType2LeaveYourPhone}</p>

                                {isLoading ? (
                                    <div className="callUs__spinner">
                                        <Spinner />
                                    </div>
                                ) : (
                                    <>
                                        <div className={`animatedInput ${!isPhoneValid ? "error" : ""}`}>
                                            <input
                                                id="phone2"
                                                value={phoneInput}
                                                className="phoneMask"
                                                type="tel"
                                                inputMode="tel"
                                                placeholder=" "
                                                name="phone"
                                                onChange={changeInputHandler}
                                            />
                                            <label htmlFor="phone2">{languages.callUsMobPhone}</label>
                                        </div>
                                        <div className="callUs__submitRow">
                                            <button
                                                className="blueButton"
                                                onClick={() => sendFeedbackToGoogleSheets(phoneInput)}
                                            >
                                                {languages.callUsSend}
                                            </button>
                                            {languages.callUsClickingYou}
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                        {isError && <h4 className="callUs__error">{languages.purchasePageError}</h4>}
                    </div>
                    <div className="callUs__contacts">
                        <span>{languages.callUsYouSelf}</span>
                        <div className="callUs__contact">
                            <label>{languages.callUsPhone}</label>
                            <p>+35770070117</p>
                        </div>
                        <div className="callUs__contact">
                            <label>Email</label>
                            <p>info@swiftglobal.cy</p>
                        </div>
                        <div className="callUs__links">
                            <button className="roundButton-light">
                                <img alt="" src={inst} />
                            </button>
                            <button className="roundButton-light">
                                <img alt="" src={fb} />
                            </button>
                            <button className="roundButton-light">
                                <img alt="" src={linked} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className="callUs">
            <div className="container">
                {isPhoneSent ? (
                    <>
                        <div>
                            <h2 className="callUs__sentTitle">
                                {languages.callUsPhoneSentTitle} <img src={checkBig} alt="" />
                            </h2>
                        </div>

                        <div className="callUs__content">
                            {languages.callUsPhoneSentMessage} {phoneInput}
                            <div className="callUs__submitRow">
                                <button className="blueButton" onClick={clearForm}>
                                    {languages.callUsPhoneSentOk}
                                </button>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        {props.text}

                        <div className="callUs__content">
                            {languages.callUsType1AnyQuestions}

                            {isLoading ? (
                                <div className="callUs__spinner">
                                    <Spinner />
                                </div>
                            ) : (
                                <>
                                    <div className={`animatedInput ${!isPhoneValid ? "error" : ""}`}>
                                        <input
                                            id="phone1"
                                            value={phoneInput}
                                            className="phoneMask"
                                            type="tel"
                                            inputMode="tel"
                                            placeholder=" "
                                            name="phone"
                                            onChange={changeInputHandler}
                                        />
                                        <label htmlFor="phone1">{languages.callUsMobPhone}</label>
                                    </div>
                                    <div className="callUs__submitRow">
                                        <button
                                            className="blueButton"
                                            onClick={() => sendFeedbackToGoogleSheets(phoneInput)}
                                        >
                                            {languages.callUsSend}
                                        </button>
                                        {languages.callUsClickingYou}
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                )}
                {isError && <h4 className="callUs__error">{languages.purchasePageError}</h4>}
            </div>
        </div>
    );
}
