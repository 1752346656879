import React from 'react';
import './PictureWithButtons.scss';
import storeMetalsImg from './imgs/storeMetalsImg.jpg';
import sellMetalsImg from './imgs/sellMetalsImg.jpg';
import {setFormOpened} from "../../Redux/reducers/formReducer";
import {useDispatch, useSelector} from "react-redux";

export default function PictureWithButtons(props) {
    let dispatch = useDispatch();
    let languages = useSelector(state => state.languages)

    if(props.type === 1){
        return (
            <div className="pictureWithButtons alwaysDark">
                <div className="container">
                    <div className="pictureWithButtons__content storeMetals">
                        <h3 className="thin">{languages.pictureWithButtonsAboutYear}</h3>
                        {languages.pictureWithButtonsWeWorksWith}
                        {languages.pictureWithButtonsPriceForStoring}
                        <button onClick={() => {dispatch(setFormOpened())}} className="blueButton">{languages.pictureWithButtonsBookPlace}</button>
                    </div>
                    <img alt="" src={storeMetalsImg}/>
                </div>
            </div>
        );
    }
    if(props.type === 2){
        return (
            <div className="pictureWithButtons">
                <div className="container">
                    <div className="pictureWithButtons__content sellMetals">
                        <h3 className="thin">{languages.pictureWithButtonsBookMetals}</h3>
                        <h2>{languages.pictureWithButtonsBookMetalsOnline}</h2>
                        {languages.pictureWithButtonsBookMetalsText}
                        <div className="pictureWithButtons__buttonsRow">
                            <button onClick={() => {dispatch(setFormOpened())}} className="blueButton">{languages.sellMetalsBookOnline}</button>
                            <button onClick={() => {dispatch(setFormOpened())}} className="grayButton">{languages.sellMetalsBookCall}</button>
                        </div>
                    </div>
                    <img className="img2" alt="" src={sellMetalsImg}/>
                </div>
            </div>
        );
    }
}