import React, {useEffect, useState} from 'react';
import './Services.scss';

import arrowRight from './imgs/arrowRight.svg';
import arrowRightBlack from './imgs/arrowRightBlack.svg';
import {useNavigate} from "react-router-dom";
import arrowLeft from "../../pages/MainPage/components/AboutBlock/imgs/arrowLeft.svg";
import {useSelector} from "react-redux";

export default function Services(props) {

    const [isOnStart, setIsOnStart] = useState(true);
    const [isOnEnd, setIsOnEnd] = useState(false);

    const [block, setBlock] = useState(1);

    let el = document.getElementsByClassName('services')[0];
    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)

    const minSwipeDistance = 50

    const onTouchStart = (e) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

    let limit = 4;
    if(props.page === "storeMetals" || props.page === "storeMoney" || props.page === "sellMetals" || props.page === "buyMetals"){
        limit = 3;
    }
    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if (isLeftSwipe || isRightSwipe) {
            if(isLeftSwipe){
                if(block !== limit){
                    if(el){
                        setBlock(block + 1)
                        el.scrollBy({
                            top: 0,
                            left: 300,
                            behavior: 'smooth'
                        });
                    }
                }
            }
            if(isRightSwipe){
                if(block !== 1){
                    if(el){
                        setBlock(block - 1)
                        el.scrollBy({
                            top: 0,
                            left: -300,
                            behavior: 'smooth'
                        });
                    }
                }
            }
        }
    }

    function onStart() {
        setIsOnStart(!isOnStart);
    }

    function onEnd() {
        setIsOnEnd(!isOnEnd);
    }

    useEffect(() => {
        if(el){
            el.scrollBy({
                top: 0,
                left: 1000,
                behavior: 'smooth'
            });
        }
    }, [el, props.isOnEnd]);
    useEffect(() => {
        if(el){
            el.scrollBy({
                top: 0,
                left: -1000,
                behavior: 'smooth'
            });
        }
    }, [el, props.isOnStart]);

    useEffect(() => {
        if(el){
            el.scrollBy({
                top: 0,
                left: 1000,
                behavior: 'smooth'
            });
        }
    }, [el, isOnEnd]);
    useEffect(() => {
        if(el){
            el.scrollBy({
                top: 0,
                left: -1000,
                behavior: 'smooth'
            });
        }
    }, [el, isOnStart]);

    let navigate = useNavigate();
    let languages = useSelector(state => state.languages)
    if(props.page === "storeMetals"){
        return(
            <div className="services__outer">
                <div className="container">
                    <h2>{languages.servicesOtherServices}</h2>
                </div>
                <div onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} className="services services-solo">
                    <div className="container container-services">
                        <div className="services__title">
                            <h2>{languages.servicesOtherServices}</h2>
                        </div>
                        <div className="services__holder">
                            <div onClick={() => {navigate('/storeMoney')}} className="services__card services__card-2">
                                <h3>{languages.servicesMoneyStore}</h3>
                                {languages.servicesMoneyStoreText}
                                <div className="services__circle"/>
                                <img className="services__card-image services__card-2-image" src="/images/services/service2.png" alt=""/>
                                <button className="roundButton-dark">
                                    <img alt="" src={arrowRight}/>
                                </button>
                            </div>
                            <div onClick={() => {navigate('/sellMetals')}} className="services__card services__card-3">
                                <h3>{languages.servicesMoneyBuy}</h3>
                                {languages.servicesMoneyBuyText}
                                <div className="services__circle"/>
                                <img className="services__card-image services__card-3-image" src="/images/services/service3.png" alt=""/>
                                <button className="roundButton-dark">
                                    <img alt="" src={arrowRight}/>
                                </button>
                            </div>
                            <div onClick={() => {navigate('/buyMetals')}} className="services__card services__card-4">
                                <h3>{languages.servicesMetalsSellText}</h3>
                                {languages.servicesMetalsSellText}
                                <div className="services__circle"/>
                                <img className="services__card-image services__card-4-image" src="/images/services/service4.png" alt=""/>
                                <button className="roundButton-dark">
                                    <img alt="" src={arrowRight}/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="services__dots">
                    <div className={block === 1 ? "dot active" : "dot"}/>
                    <div className={block === 2 ? "dot active" : "dot"}/>
                    <div className={block === 3 ? "dot active" : "dot"}/>
                </div>
            </div>
        );

    }
    if(props.page === "storeMoney"){
        return(
            <div className="services__outer">
                <div className="container">
                    <h2>{languages.servicesOtherServices}</h2>
                </div>
                <div onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} className="services services-solo">
                    <div className="container container-services">
                        <div className="services__title">
                            <h2>{languages.servicesOtherServices}</h2>
                        </div>
                        <div className="services__holder">
                            <div onClick={() => {navigate('/storeMetals')}} className="services__card services__card-1">
                                <h3>{languages.servicesStoreMetals}</h3>
                                {languages.servicesStoreMetalsText}
                                <div className="services__circle"/>
                                <img className="services__card-image services__card-1-image" src="/images/services/service1.png" alt=""/>
                                <button className="roundButton-dark">
                                    <img alt="" src={arrowRight}/>
                                </button>
                            </div>
                            <div onClick={() => {navigate('/sellMetals')}} className="services__card services__card-3">
                                <h3>{languages.servicesMoneyBuy}</h3>
                                {languages.servicesMoneyBuyText}
                                <div className="services__circle"/>
                                <img className="services__card-image services__card-3-image" src="/images/services/service3.png" alt=""/>
                                <button className="roundButton-dark">
                                    <img alt="" src={arrowRight}/>
                                </button>
                            </div>
                            <div onClick={() => {navigate('/buyMetals')}} className="services__card services__card-4">
                                <h3>{languages.servicesMetalsSell}</h3>
                                {languages.servicesMetalsSellText}
                                <div className="services__circle"/>
                                <img className="services__card-image services__card-4-image" src="/images/services/service4.png" alt=""/>
                                <button className="roundButton-dark">
                                    <img alt="" src={arrowRight}/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="services__dots">
                    <div className={block === 1 ? "dot active" : "dot"}/>
                    <div className={block === 2 ? "dot active" : "dot"}/>
                    <div className={block === 3 ? "dot active" : "dot"}/>
                    <div className={block === 4 ? "dot active" : "dot"}/>
                </div>
            </div>


        );

    }
    if(props.page === "sellMetals"){
        return(
            <div className="services__outer">
                <div className="container">
                    <h2>{languages.servicesOtherServices}</h2>
                </div>
                <div className="services services-solo">
                    <div className="container container-services">
                        <div className="services__title">
                            <h2>{languages.servicesOtherServices}</h2>
                        </div>
                        <div className="services__holder">
                            <div onClick={() => {navigate('/storeMetals')}} className="services__card services__card-1">
                                <h3>{languages.servicesStoreMetals}</h3>
                                {languages.servicesStoreMetalsText}
                                <div className="services__circle"/>
                                <img className="services__card-image services__card-1-image" src="/images/services/service1.png" alt=""/>
                                <button className="roundButton-dark">
                                    <img alt="" src={arrowRight}/>
                                </button>
                            </div>
                            <div onClick={() => {navigate('/storeMoney')}} className="services__card services__card-2">
                                <h3>{languages.servicesMoneyStore}</h3>
                                {languages.servicesMoneyStoreText}
                                <div className="services__circle"/>
                                <img className="services__card-image services__card-2-image" src="/images/services/service2.png" alt=""/>
                                <button className="roundButton-dark">
                                    <img alt="" src={arrowRight}/>
                                </button>
                            </div>
                            <div onClick={() => {navigate('/buyMetals')}} className="services__card services__card-4">
                                <h3>{languages.servicesMetalsSell}</h3>
                                {languages.servicesMetalsSellText}
                                <div className="services__circle"/>
                                <img className="services__card-image services__card-4-image" src="/images/services/service4.png" alt=""/>
                                <button className="roundButton-dark">
                                    <img alt="" src={arrowRight}/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="services__dots">
                    <div className={block === 1 ? "dot active" : "dot"}/>
                    <div className={block === 2 ? "dot active" : "dot"}/>
                    <div className={block === 3 ? "dot active" : "dot"}/>
                    <div className={block === 4 ? "dot active" : "dot"}/>
                </div>
            </div>

        );

    }
    if(props.page === "buyMetals"){
        return(
            <div className="services__outer">
                <div className="container">
                    <h2>{languages.servicesOtherServices}</h2>
                </div>
                <div className="services services-solo">
                    <div className="container container-services">
                        <div className="services__title">
                            <h2>{languages.servicesOtherServices}</h2>
                        </div>
                        <div className="services__holder">
                            <div onClick={() => {navigate('/storeMetals')}} className="services__card services__card-1">
                                <h3>{languages.servicesStoreMetals}</h3>
                                {languages.servicesStoreMetalsText}
                                <div className="services__circle"/>
                                <img className="services__card-image services__card-1-image" src="/images/services/service1.png" alt=""/>
                                <button className="roundButton-dark">
                                    <img alt="" src={arrowRight}/>
                                </button>
                            </div>
                            <div onClick={() => {navigate('/storeMoney')}} className="services__card services__card-2">
                                <h3>{languages.servicesMoneyStore}</h3>
                                {languages.servicesMoneyStoreText}
                                <div className="services__circle"/>
                                <img className="services__card-image services__card-2-image" src="/images/services/service2.png" alt=""/>
                                <button className="roundButton-dark">
                                    <img alt="" src={arrowRight}/>
                                </button>
                            </div>
                            <div onClick={() => {navigate('/sellMetals')}} className="services__card services__card-3">
                                <h3>{languages.servicesMoneyBuy}</h3>
                                {languages.servicesMoneyBuyText}
                                <div className="services__circle"/>
                                <img className="services__card-image services__card-3-image" src="/images/services/service3.png" alt=""/>
                                <button className="roundButton-dark">
                                    <img alt="" src={arrowRight}/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="services__dots">
                    <div className={block === 1 ? "dot active" : "dot"}/>
                    <div className={block === 2 ? "dot active" : "dot"}/>
                    <div className={block === 3 ? "dot active" : "dot"}/>
                    <div className={block === 4 ? "dot active" : "dot"}/>
                </div>
            </div>

        );

    }
    if(props.page === "all"){
        return(
            <div className="services-block">
                <div className="container">
                    <h2 className="services__h2-all">{languages.servicesOurServices}</h2>
                </div>
                <div className="services services-solo">
                    <div className="container container-services container-services_all">
                        <div className="services__holder services__holder-all">
                            <div onClick={() => {navigate('/storeMetals')}} className="services__card services__card-1">
                                <h3>{languages.servicesStoreMetals}</h3>
                                {languages.servicesStoreMetalsText}
                                <div className="services__circle"/>
                                <img className="services__card-image services__card-1-image" src="/images/services/service1.png" alt=""/>
                                <button className="roundButton-dark">
                                    <img alt="" src={arrowRight}/>
                                </button>
                            </div>
                            <div onClick={() => {navigate('/storeMoney')}} className="services__card services__card-2">
                                <h3>{languages.servicesMoneyStore}</h3>
                                {languages.servicesMoneyStoreText}
                                <div className="services__circle"/>
                                <img className="services__card-image services__card-2-image" src="/images/services/service2.png" alt=""/>
                                <button className="roundButton-dark">
                                    <img alt="" src={arrowRight}/>
                                </button>
                            </div>
                            <div onClick={() => {navigate('/sellMetals')}} className="services__card services__card-3">
                                <h3>{languages.servicesMoneyBuy}</h3>
                                {languages.servicesMoneyBuyText}
                                <div className="services__circle"/>
                                <img className="services__card-image services__card-3-image" src="/images/services/service3.png" alt=""/>
                                <button className="roundButton-dark">
                                    <img alt="" src={arrowRight}/>
                                </button>
                            </div>
                            <div onClick={() => {navigate('/buyMetals')}} className="services__card services__card-4">
                                {languages.servicesMetalsSell}
                                {languages.servicesMetalsSellText}
                                <div className="services__circle"/>
                                <img className="services__card-image services__card-4-image" src="/images/services/service4.png" alt=""/>
                                <button className="roundButton-dark">
                                    <img alt="" src={arrowRight}/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="services__dots">
                    <div className={block === 1 ? "dot active" : "dot"}/>
                    <div className={block === 2 ? "dot active" : "dot"}/>
                    <div className={block === 3 ? "dot active" : "dot"}/>
                    <div className={block === 4 ? "dot active" : "dot"}/>
                </div>
                <div className="container">
                    <div className="services__controlsRow">
                        <div className="aboutBlock__controlsRow">
                            <button className="roundButton-light" onClick={onStart}>
                                <img alt="" src={arrowLeft}/>
                            </button>
                            <button className="roundButton-light" onClick={onEnd}>
                                <img alt="" src={arrowRightBlack}/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        );

    }



    return(
        <>
            <div onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} className="services">
                <div onClick={() => {navigate('/storeMetals')}} className="services__card services__card-1">
                    <h3>{languages.servicesStoreMetals}</h3>
                    {languages.servicesStoreMetalsText}
                    <div className="services__circle"/>
                    <img className="services__card-image services__card-1-image" src="/images/services/service1.png" alt=""/>
                    <button className="roundButton-dark">
                        <img alt="" src={arrowRight}/>
                    </button>
                </div>
                <div onClick={() => {navigate('/storeMoney')}} className="services__card services__card-2">
                    <h3>{languages.servicesMoneyStore}</h3>
                    {languages.servicesMoneyStoreText}
                    <div className="services__circle"/>
                    <img className="services__card-image services__card-2-image" src="/images/services/service2.png" alt=""/>
                    <button className="roundButton-dark">
                        <img alt="" src={arrowRight}/>
                    </button>
                </div>
                <div onClick={() => {navigate('/sellMetals')}} className="services__card services__card-3">
                    <h3>{languages.servicesMoneyBuy}</h3>
                    {languages.servicesMoneyBuyText}
                    <div className="services__circle"/>
                    <img className="services__card-image services__card-3-image" src="/images/services/service3.png" alt=""/>
                    <button className="roundButton-dark">
                        <img alt="" src={arrowRight}/>
                    </button>
                </div>
                <div onClick={() => {navigate('/buyMetals')}} className="services__card services__card-4">
                    <h3>{languages.servicesMetalsSell}</h3>
                    {languages.servicesMetalsSellText}
                    <div className="services__circle"/>
                    <img className="services__card-image services__card-4-image" src="/images/services/service4.png" alt=""/>
                    <button className="roundButton-dark">
                        <img alt="" src={arrowRight}/>
                    </button>
                </div>
            </div>
            <div className="services__dots">
                <div className={block === 1 ? "dot active" : "dot"}/>
                <div className={block === 2 ? "dot active" : "dot"}/>
                <div className={block === 3 ? "dot active" : "dot"}/>
                <div className={block === 4 ? "dot active" : "dot"}/>
            </div>
        </>

    );
}