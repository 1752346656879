import React from 'react';
import './StoreMetalsPage.scss';
import Header from "../../components/Header/Header";
import Banner from "../../components/Banner/Banner";
import AboutService from "../../components/AboutService/AboutService";
import InfoPoints from "../../components/InfoPoints/InfoPoints";
import Calculator from "../../components/Calculator/Calculator";
import Safety from "../../components/Safety/Safety";
import PictureWithButtons from "../../components/PictureWithButton/PictureWithButtons";
import Services from "../../components/Services/Services";
import InfoTable from "../../components/InfoTable/InfoTable";
import CallUs from "../../components/CallUs/CallUs";
import Footer from "../../components/Footer/Footer";
import {useSelector} from "react-redux";

export default function StoreMetalsPage() {
    let languages = useSelector(state => state.languages)

    return (
        <div className="storeMetalsPage">
            <Header color="gray"/>
            <Banner type={1}/>
            <AboutService type={1}/>
            <InfoPoints type={1}/>
            <Calculator/>
            <Safety/>
            <PictureWithButtons type={1}/>
            <div className="storeMetalsPage__info">
                <div className="container">
                    {languages.storeMetalsPageWhenWeOffer}
                    <InfoTable/>
                </div>
            </div>
            <CallUs text={languages.storeMetalsPageCallUs}/>
            <Services page="storeMetals"/>
            <Footer/>
        </div>
    );
}