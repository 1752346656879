import React from "react";
import { useSelector } from "react-redux";

import CallUs from "../../components/CallUs/CallUs";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ContactMap from "components/ContactMap/ContactMap";
import "./ContactsPage.scss";

export default function ContactsPage() {
    let languages = useSelector((state) => state.languages);

    return (
        <div className="contactsPage">
            <Header />
            <div className="contactsPage__content">
                <div className="container">
                    <label>{languages.headerContacts}</label>
                </div>
            </div>
            <CallUs type={2} text={languages.contactsPageCallUs} />
            <div className="container contactsPage__mapTitle">
                <h2>{languages.contactsPageAddresses}</h2>
                <h3 className="thin">{languages.contactsPageOffice}</h3>
                <p>35, Theklas Lisioti Str, 3030, Limassol, Cyprus</p>
            </div>
            <div className="contactsPage__map">
                <ContactMap />
            </div>
            <Footer />
        </div>
    );
}
