import "./App.css";
import "main.scss";
import { Route, Routes, useLocation } from "react-router-dom";
import MainPage from "./pages/MainPage/MainPage";
import StoreMetalsPage from "./pages/StoreMetalsPage/StoreMetalsPage";
import StoreMoneyPage from "./pages/StoreMoneyPage/StoreMoneyPage";
import SellMetalsPage from "./pages/SellMetalsPage/SellMetalsPage";
import BuyMetalsPage from "./pages/BuyMetalsPage/BuyMetalsPage";
import ZeroFeesPage from "./pages/ZeroFeesPage/ZeroFeesPage";
import FullInsurancePage from "./pages/FullInsurance/FullInsurance";
import CheckingMetalsPage from "./pages/CheckingMetals/CheckingMetals";
import UnlimitedExportPage from "./pages/UnlimitedImport/UnlimitedImport";
import Basel3Page from "./pages/Basel3Page/Basel3Page";
import FullConfidencePage from "./pages/FullConfidence/FullConfidence";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AboutPage from "./pages/AboutPage/AboutPage";
import ContactsPage from "./pages/ContactsPage/ContactsPage";
import ServicesPage from "./pages/ServicesPage/ServicesPage";
import SupportPage from "./pages/SupportPage/SupportPage";
import StoragePage from "./pages/StoragePage/StoragePage";
import PolicyPage from "./pages/PolicyPage/PolicyPage";
import PurchasePage from "./pages/PurchasePage/PurchasePage";
import Popup from "./components/Popup/Popup";
import SetPreferredLanguageFromURL from "hoc/SetPreferredLanguageFromURL";

function App() {
  let location = useLocation();

  return (
    <>
      <TransitionGroup component={null}>
        <CSSTransition timeout={300} classNames="page" key={location.key}>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="ru">
              <Route path="" element={<MainPage />} />
              <Route path="storeMetals" element={<StoreMetalsPage />} />
              <Route path="storeMoney" element={<StoreMoneyPage />} />
              <Route path="sellMetals" element={<SellMetalsPage />} />
              <Route path="buyMetals" element={<BuyMetalsPage />} />
              <Route path="zeroFees" element={<ZeroFeesPage />} />
              <Route path="fullInsurance" element={<FullInsurancePage />} />
              <Route path="checkingMetals" element={<CheckingMetalsPage />} />
              <Route path="unlimitedExport" element={<UnlimitedExportPage />} />
              <Route path="basel3" element={<Basel3Page />} />
              <Route path="fullConfidence" element={<FullConfidencePage />} />
              <Route path="about" element={<AboutPage />} />
              <Route path="contacts" element={<ContactsPage />} />
              <Route path="services" element={<ServicesPage />} />
              <Route path="support" element={<SupportPage />} />
              <Route path="storage" element={<StoragePage />} />
              <Route path="policy" element={<PolicyPage />} />
              <Route path="buy" element={<PurchasePage />} />
            </Route>
            <Route path="en">
              <Route path="" element={<MainPage />} />
              <Route path="storeMetals" element={<StoreMetalsPage />} />
              <Route path="storeMoney" element={<StoreMoneyPage />} />
              <Route path="sellMetals" element={<SellMetalsPage />} />
              <Route path="buyMetals" element={<BuyMetalsPage />} />
              <Route path="zeroFees" element={<ZeroFeesPage />} />
              <Route path="fullInsurance" element={<FullInsurancePage />} />
              <Route path="checkingMetals" element={<CheckingMetalsPage />} />
              <Route path="unlimitedExport" element={<UnlimitedExportPage />} />
              <Route path="basel3" element={<Basel3Page />} />
              <Route path="fullConfidence" element={<FullConfidencePage />} />
              <Route path="about" element={<AboutPage />} />
              <Route path="contacts" element={<ContactsPage />} />
              <Route path="services" element={<ServicesPage />} />
              <Route path="support" element={<SupportPage />} />
              <Route path="storage" element={<StoragePage />} />
              <Route path="policy" element={<PolicyPage />} />
              <Route path="buy" element={<PurchasePage />} />
            </Route>
            <Route path="ch">
              <Route path="" element={<MainPage />} />
              <Route path="storeMetals" element={<StoreMetalsPage />} />
              <Route path="storeMoney" element={<StoreMoneyPage />} />
              <Route path="sellMetals" element={<SellMetalsPage />} />
              <Route path="buyMetals" element={<BuyMetalsPage />} />
              <Route path="zeroFees" element={<ZeroFeesPage />} />
              <Route path="fullInsurance" element={<FullInsurancePage />} />
              <Route path="checkingMetals" element={<CheckingMetalsPage />} />
              <Route path="unlimitedExport" element={<UnlimitedExportPage />} />
              <Route path="basel3" element={<Basel3Page />} />
              <Route path="fullConfidence" element={<FullConfidencePage />} />
              <Route path="about" element={<AboutPage />} />
              <Route path="contacts" element={<ContactsPage />} />
              <Route path="services" element={<ServicesPage />} />
              <Route path="support" element={<SupportPage />} />
              <Route path="storage" element={<StoragePage />} />
              <Route path="policy" element={<PolicyPage />} />
              <Route path="buy" element={<PurchasePage />} />
            </Route>
          </Routes>
        </CSSTransition>
      </TransitionGroup>

      <Popup />
      <SetPreferredLanguageFromURL />
    </>
  );
}

export default App;
