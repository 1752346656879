import { useDispatch, useSelector } from "react-redux";
import { setChinese, setEnglish, setRussian } from "../../Redux/reducers/languagesReducer";
import { changeLanguageInURL } from "../../helpers/utilFunctions";
import HideComponentOnOutsideClickOrEscapePress from "../../hoc/HideComponentOnOutsideClickOrEscapePress";
import "./LanguagesDropdownMenu.scss";
import { useRef } from "react";

const LanguagesDropdownMenu = ({ hideComponent }) => {
    const languages = useSelector((state) => state.languages);

    const ref = useRef(null);

    const dispatch = useDispatch();

    function switchToRussian() {
        if (languages.language !== "RU") {
            dispatch(setRussian());
            changeLanguageInURL("ru");

            hideComponent();
        }
    }

    function switchToEnglish() {
        if (languages.language !== "ENG") {
            dispatch(setEnglish());
            changeLanguageInURL("en");

            hideComponent();
        }
    }

    function switchToChinese() {
        if (languages.language !== "CHN") {
            dispatch(setChinese());
            changeLanguageInURL("cn");

            hideComponent();
        }
    }

    return (
        <HideComponentOnOutsideClickOrEscapePress hideComponent={hideComponent} innerRef={ref}>
            <div className="switch-languages__dropdown dropdown" ref={ref}>
                <button
                    onClick={switchToRussian}
                    className={`button--text switch-language__select ${languages.language === "RU" ? "active" : ""}`}
                >
                    RU
                </button>
                <button
                    onClick={switchToEnglish}
                    className={`button--text switch-language__select ${languages.language === "ENG" ? "active" : ""}`}
                >
                    ENG
                </button>
                <button
                    onClick={switchToChinese}
                    className={`button--text switch-language__select ${languages.language === "CHN" ? "active" : ""}`}
                >
                    CHN
                </button>
            </div>
        </HideComponentOnOutsideClickOrEscapePress>
    );
};

export default LanguagesDropdownMenu;
