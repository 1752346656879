import { API_URL } from "../../config";

const SET_METALS = "SET_METALS";
const SET_LOADING = "SET_LOADING";
const SET_ERROR = "SET_ERROR";

const defaultState = {
    metalsData: {},
    isLoading: false,
    error: null,
};

export default function metalsReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_METALS:
            return {
                ...state,
                metalsData: action.payload,
            };

        case SET_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        case SET_ERROR:
            return {
                ...state,
                hasError: action.payload,
            };
        default:
            return state;
    }
}

export const fetchMetalPrices = () => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING, payload: true });

        const response = await fetch(`${API_URL}/getMetalsPrices.php`);

        if (!response.ok) {
            dispatch({ type: SET_ERROR, payload: true });

            return;
        }

        dispatch({ type: SET_METALS, payload: await response.json() });
    } catch (e) {
        console.log(e);
        dispatch({ type: SET_ERROR, payload: true });
    } finally {
        dispatch({ type: SET_LOADING, payload: false });
    }
};
