import { API_URL } from "config";

export const submitRequest = async (phone) => {
    try {
        const submitRequestResponse = await fetch(`${API_URL}/submitRequest.php`, {
            method: "POST",
            body: JSON.stringify({ phone }),
        });

        if (!submitRequestResponse.ok) {
            return null;
        }

        return submitRequestResponse.json();
    } catch (err) {
        console.log(err);
        return null;
    }
};

export const sendOrder = async (data) => {
    try {
        const response = await fetch(`${API_URL}/sendOrder.php`, {
            method: "POST",
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            return null;
        }

        return response.json();
    } catch (err) {
        console.log(err);

        return null;
    }
};
