import React from "react";

import Checkbox from "components/Svg/Checkbox";

const PurchaseDeliveryTypeField = ({ type, label, isChecked, onClickHandler }) => {
    return (
        <div
            className="delivery-type animatedCheckbox"
            onClick={() => {
                onClickHandler(type);
            }}
        >
            <div className={`checkbox${isChecked ? " checked" : ""}`}>
                <Checkbox />
            </div>
            <p>{label}</p>
        </div>
    );
};

export default PurchaseDeliveryTypeField;
