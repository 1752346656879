import {useSelector} from "react-redux";
import React from 'react';

import './Basel3Page.scss';
import Header from "../../components/Header/Header";
import OtherBanner from "../../components/OtherBanner/OtherBanner";
import AboutService from "../../components/AboutService/AboutService";
import OtherOffers from "../../components/OtherOffers/OtherOffers";
import Services from "../../components/Services/Services";
import CallUs from "../../components/CallUs/CallUs";
import Footer from "../../components/Footer/Footer";
import PictureWithText from "../../components/PictureWithText/PictureWithText";

export default function Basel3Page() {
    let languages = useSelector((state) => state.languages);

    return (
        <div className="basel3Page">
            <Header/>
            <OtherBanner page="Basel3Page"/>
            <PictureWithText type={2}/>
            <AboutService type={9}/>
            <OtherOffers type={5}/>
            <Services page="all"/>
            <CallUs text={languages.mainPageCallUs}/>
            <Footer/>
        </div>
    );
}