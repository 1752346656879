import { API_URL } from "config";
// import { addDoc, collection, getFirestore } from "firebase/firestore";
// import { addDoc, collection, getFirestore } from "firebase/firestore";
// import { app } from "helpers/firebase";

const getOrderDetails = (order) =>
    order.map(({ name, price, quantity }) => ({
        name: name["ENG"],
        quantity,
        price: Number((price * quantity).toFixed(2)),
    }));

const getOrderDetailsString = (order) =>
    order
        .map(
            ({ name, price, quantity }) =>
                `${name["RU"]}\nКоличество: ${quantity}\nЦена: $${Number((price * quantity).toFixed(2))}`
        )
        .join("\n\n");

export const sendMailToCustomer = async (email, order, totalPrice) => {
    try {
        const data = {
            email,
            order: getOrderDetails(order),
            prepayment: (totalPrice / 10).toFixed(2),
            paid: (totalPrice * 0.9).toFixed(2),
            totalPrice: totalPrice.toFixed(2),
        };
        const response = await fetch(`${API_URL}/sendMail.php`, {
            method: "POST",
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            return null;
        }

        return response.json();
    } catch (err) {
        return null;
    }
};

export const createOrder = (form, cart, price) => {
    const deliveryTypes = {
        storage: "Доставка в хранилище",
        mail: "Доставка почтой",
    };

    const deliveryAddresses = {
        cyprus: "Кипр",
        dubai: "Дубай",
    };

    const order = getOrderDetailsString(cart);
    const deliveryAddress =
        form.deliveryType === "storage" ? deliveryAddresses[form.deliveryCountry] : form.deliveryAddress;

    return {
        last_name: form.lastName,
        first_name: form.name,
        email: form.email,
        phone: form.phone,
        delivery_type: deliveryTypes[form.deliveryType],
        delivery_address: deliveryAddress,
        price: Math.ceil(price),
        order,
    };
};

// export const saveOrderToDB = async (form, cart) => {
//     const deliveryTypes = {
//         storage: "Доставка в хранилище",
//         mail: "Доставка почтой",
//     };
//
//     const deliveryAddresses = {
//         cyprus: "Кипр",
//         dubai: "Дубай",
//     };
//
//     try {
//         const db = getFirestore(app);
//         const order = getOrderDetails(cart);
//         const date = new Date().toLocaleDateString("en-US", {
//             year: "numeric",
//             month: "2-digit",
//             day: "2-digit",
//             hour: "2-digit",
//             minute: "2-digit",
//             second: "2-digit",
//             hour12: false,
//         });
//         const deliveryAddress =
//             form.deliveryType === "storage"
//                 ? deliveryAddresses[form.deliveryCountry]
//                 : form.deliveryAddress;
//         const data = {
//             date,
//             last_name: form.lastName,
//             name: form.name,
//             email: form.email,
//             phone: form.phone,
//             delivery_type: deliveryTypes[form.deliveryType],
//             delivery_address: deliveryAddress,
//             order,
//         };
//
//         const docRef = await addDoc(collection(db, "orders"), data);
//
//         return docRef.id;
//     } catch (e) {
//         console.log(e)
//         return null;
//     }
// };
