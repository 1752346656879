import { LocalStorage } from "helpers/localstorage";

const SET_CART = "SET_CART";
const SET_CART_ITEM = "SET_CART_ITEM";
const CHANGE_QUANTITY = "CHANGE_QUANTITY";
const REMOVE_FROM_CART = "REMOVE_FROM_CART";
const SET_CART_SHOW = "SET_CART_SHOW";
const SET_CART_HIDE = "SET_CART_HIDE";

const defaultState = {
    cart: [],
    isShowCart: false,
};

export default function cartReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_CART:
            const cart = action.payload;

            if (cart.length) {
                LocalStorage.setCart(cart);
            } else {
                LocalStorage.clearCart();
            }

            return {
                ...state,
                cart,
            };
        case SET_CART_ITEM:
            const item = action.payload;
            const updatedCart = [...state.cart];
            const isItemInCart = updatedCart.findIndex(({ id }) => id === item.id);

            if (isItemInCart === -1) {
                updatedCart.push({
                    ...item,
                    quantity: item.quantity,
                });
            } else {
                updatedCart[isItemInCart].quantity += item.quantity;
            }

            LocalStorage.setCart(updatedCart);

            return {
                ...state,
                cart: updatedCart,
            };
        case CHANGE_QUANTITY:
            const itemQuantity = action.payload;
            let updatedCartQuantity = [...state.cart];
            const isItemInCartQuantity = updatedCartQuantity.findIndex(({ id }) => id === itemQuantity.id);

            if (isItemInCartQuantity === -1) {
                return { ...state };
            }

            if (itemQuantity.quantity === 0) {
                updatedCartQuantity = updatedCartQuantity.filter(({ id }) => id !== itemQuantity.id);
            } else {
                updatedCartQuantity[isItemInCartQuantity].quantity = itemQuantity.quantity;
            }
            if (updatedCartQuantity.length) {
                LocalStorage.setCart(updatedCartQuantity);
            } else {
                LocalStorage.clearCart();
            }

            return { ...state, cart: updatedCartQuantity };
        case REMOVE_FROM_CART:
            const removedItem = action.payload;
            const updatedCartAfterRemoveItem = [...state.cart].filter(({ id }) => id !== removedItem.id);

            if (updatedCartAfterRemoveItem.length) {
                LocalStorage.setCart(updatedCartAfterRemoveItem);
            } else {
                LocalStorage.clearCart();
            }

            return { ...state, cart: updatedCartAfterRemoveItem };
        case SET_CART_SHOW:
            return { ...state, isShowCart: true };
        case SET_CART_HIDE:
            return { ...state, isShowCart: false };
        default:
            return state;
    }
}

export const setCart = (cartData) => ({ type: SET_CART, payload: cartData });
export const setCartItem = (item) => ({ type: SET_CART_ITEM, payload: item });
export const changeQuantityInCart = (item) => ({ type: CHANGE_QUANTITY, payload: item });
export const removeFromCart = (item) => ({ type: REMOVE_FROM_CART, payload: item });
export const showCart = () => ({ type: SET_CART_SHOW });
export const hideCart = () => ({ type: SET_CART_HIDE });
