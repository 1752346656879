import React from 'react';
import './InfoTable.scss'
import smallArrowRight from "../../imgs/smallArrowRight.svg";

import gold from './imgs/gold.svg';
import graph from './imgs/graph.svg';
import plane from './imgs/plane.svg';
import sale from './imgs/sale.svg';
import secureChat from './imgs/secureChat.svg';
import shield from './imgs/shield.svg';
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

export default function InfoTable() {
    let navigate = useNavigate();
    let languages = useSelector(state => state.languages)
    return(
        <div className="infoTable">
            <div className="container">
                <div className="infoTable__info infoTable__info-1">
                    <img src={sale} alt=""/>
                    {languages.infoTable1Title}
                    {languages.infoTable1Text}
                    <button onClick={() => {navigate('/zeroFees')}} className="arrowButton">
                        <label>{languages.bannerLearnMore}</label>
                        <img alt="" src={smallArrowRight}/>
                    </button>
                </div>
                <div className="infoTable__info infoTable__info-2">
                    <img src={shield} alt=""/>
                    {languages.infoTable2Title}
                    {languages.infoTable2Text}
                    <button onClick={() => {navigate('/fullInsurance')}} className="arrowButton">
                        <label>{languages.bannerLearnMore}</label>
                        <img alt="" src={smallArrowRight}/>
                    </button>
                </div>
                <div className="infoTable__info infoTable__info-3">
                    <img src={gold} alt=""/>
                    {languages.infoTable3Title}
                    {languages.infoTable3Text}
                    <button onClick={() => {navigate('/checkingMetals')}} className="arrowButton">
                        <label>{languages.bannerLearnMore}</label>
                        <img alt="" src={smallArrowRight}/>
                    </button>
                </div>
                <div className="infoTable__info infoTable__info-4">
                    <img src={plane} alt=""/>
                    {languages.infoTable4Title}
                    {languages.infoTable4Text}
                    <button onClick={() => {navigate('/unlimitedExport')}} className="arrowButton">
                        <label>{languages.bannerLearnMore}</label>
                        <img alt="" src={smallArrowRight}/>
                    </button>
                </div>
                <div className="infoTable__info infoTable__info-5">
                    <img src={graph} alt=""/>
                    {languages.infoTable5Title}
                    {languages.infoTable5Text}
                    <button onClick={() => {navigate('/basel3')}} className="arrowButton">
                        <label>{languages.bannerLearnMore}</label>
                        <img alt="" src={smallArrowRight}/>
                    </button>
                </div>
                <div className="infoTable__info infoTable__info-6">
                    <img src={secureChat} alt=""/>
                    <h3>{languages.infoTable6Title}</h3>
                    {languages.infoTable6Text}
                    <button onClick={() => {navigate('/fullConfidence')}} className="arrowButton">
                        <label>{languages.bannerLearnMore}</label>
                        <img alt="" src={smallArrowRight}/>
                    </button>
                </div>
            </div>
        </div>
    );
}