import React from 'react';
import './PolicyPage.scss';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {useSelector} from "react-redux";

export default function PolicyPage() {
    let languages = useSelector(state => state.languages)

    return (
        <div className="policyPage">
            <Header/>
            <div className="container">
                <section>
                    <h1>{languages.policyPage}</h1>
                    <p>{languages.policyPageDescription}</p>
                </section>
                <section>
                    <h2>{languages.policyPageTitle1}</h2>
                    <p></p>
                    <div className="policyPage__row">
                        <div className="policyPage__firstCol">
                            <div>
                                <div className="line"/>
                                <h3>{languages.policyPageFIO}</h3>
                            </div>
                            <div>
                                <div className="line"/>
                                <h3>{languages.policyPageEmail}</h3>
                            </div>
                            <div>
                                <div className="line"/>
                                {languages.policyPagePhone}
                            </div>
                        </div>
                        <div className="policyPage__secondCol">
                            <div>
                                <div className="line"/>
                                <h3>{languages.policyPageAddress}</h3>
                            </div>
                            <div>
                                <div className="line"/>
                                <h3>{languages.policyPagePayment}</h3>
                            </div>
                            <div>
                                <div className="line"/>
                                <h3>{languages.policyPageOtherData}</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <h2>{languages.policyPageTitle2}</h2>
                    {languages.policyPageText2}
                </section>
                <section>
                    <h2>{languages.policyPageTitle3}</h2>
                    <p>{languages.policyPageText3}</p>
                </section>
                <section>
                    <h2>{languages.policyPageTitle4}</h2>
                    <p>{languages.policyPageText4}</p>
                </section>
                <section>
                    <h2>{languages.policyPageTitle5}</h2>
                    <p>{languages.policyPageText5}</p>
                </section>
                {languages.policyPage === 'Privacy Policy' ?
                    <>
                        <section>
                            <h2>{languages.policyPageTitle6}</h2>
                            <p>{languages.policyPageText6}</p>
                        </section>
                        <section>
                            <h2>{languages.policyPageTitle7}</h2>
                            <p>{languages.policyPageText7}</p>
                        </section>
                        <section>
                        <h2>{languages.policyPageTitle8}</h2>
                        <p>{languages.policyPageText8}</p>
                        </section>
                        <section>
                        <h2>{languages.policyPageTitle9}</h2>
                        <p>{languages.policyPageText9}</p>
                        </section>
                    </>
                    :
                    <section>
                        {languages.policyPageTitle6}
                        {languages.policyPageOrganization}
                        {languages.policyPageUrAddress}
                        {languages.policyPagePostAddress}
                    </section>
                }

            </div>
            <Footer/>
        </div>
    );
}