import React from 'react';
import './StoreMoneyPage.scss';
import Header from "../../components/Header/Header";
import Banner from "../../components/Banner/Banner";
import AboutService from "../../components/AboutService/AboutService";
import InfoPoints from "../../components/InfoPoints/InfoPoints";
import InfoTable from "../../components/InfoTable/InfoTable";
import CallUs from "../../components/CallUs/CallUs";
import Services from "../../components/Services/Services";
import Footer from "../../components/Footer/Footer";
import {useSelector} from "react-redux";

export default function StoreMoneyPage() {
    let languages = useSelector(state => state.languages)

    return (
        <div className="storeMoneyPage">
            <Header color="gray"/>
            <Banner type={2}/>
            <AboutService type={2}/>
            <InfoPoints type={2}/>
            <div className="storeMoneyPage__info">
                <div className="container">
                    {languages.storeMetalsPageWhenWeOffer}
                    <InfoTable/>
                </div>
            </div>
            <CallUs text={languages.storeMoneyCallUs}/>
            <Services page="storeMoney"/>
            <Footer/>
        </div>
    );
}