import { useEffect } from "react";

export default function HideComponentOnOutsideClickOrEscapePress({ innerRef, children, hideComponent }) {
    const closeComponentOnOutsideClick = (e) => {
        if (e.target !== innerRef.current) {
            hideComponent();
        }
    };
    const closeComponentOnEscapePress = (e) => {
        if (e.key === "Escape") {
            hideComponent();
        }
    };
    const applyEventListeners = () => {
        document.addEventListener("click", closeComponentOnOutsideClick);
        document.addEventListener("keydown", closeComponentOnEscapePress);

        return () => {
            document.removeEventListener("click", closeComponentOnOutsideClick);
            document.removeEventListener("keydown", closeComponentOnEscapePress);
        };
    };

    useEffect(applyEventListeners);

    return children;
}
