import React from 'react';
import smallArrowRight from "../../imgs/smallArrowRight.svg";
import './Safety.scss'

import lloyds from './imgs/lloyds.svg';
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

export default function Safety(props) {
    let navigate =useNavigate();
    let languages = useSelector(state => state.languages)
    if(props.type === 2){
        return(
            <div className="safety">
                <div className="container">
                    <div className="safety__text">
                        <h2>{languages.safety}</h2>
                        {languages.safetyInOurStorage}
                        <img className="safety__img" src={lloyds} alt=""/>
                        <label className="safety__label">{languages.safetyYourActives}</label>
                    </div>
                    <div className="safety__table">
                        <div className="safety__tableItem">
                            <h4>{languages.safetyBlockChain}</h4>
                            {languages.safetyBlockChainText}
                        </div>
                        <div className="safety__tableItem">
                            <h4>{languages.safetyG10}</h4>
                            {languages.safetyG10Text}
                        </div>
                        <div className="safety__tableItem">
                            {languages.safetyMultiLayer}
                            {languages.safetyMultiLayerText}
                        </div>
                        <div className="safety__tableItem">
                            {languages.safety3Pairs}
                            <p>{languages.safety3PairsText}</p>
                        </div>
                    </div>
                    <div>
                        <img className="safety__img mob" src={lloyds} alt=""/>
                        <label className="safety__label mob">{languages.safetyYourActives}</label>
                    </div>

                </div>
            </div>
        );
    }
    return(
        <div className="safety">
            <div className="container">
                <div className="safety__text">
                    <h2>{languages.safety}</h2>
                    {languages.safetyInOurStorage}
                    <button onClick={() => {navigate('/storage')}} className="arrowButton">
                        <label>{languages.safetyMoreAboutStorage}</label>
                        <img alt="" src={smallArrowRight}/>
                    </button>
                </div>
                <div className="safety__table">
                    <div className="safety__tableItem">
                        <h4>{languages.safetyBlockChain}</h4>
                        {languages.safetyBlockChainText}
                    </div>
                    <div className="safety__tableItem">
                        <h4>{languages.safetyG10}</h4>
                        {languages.safetyG10Text}
                    </div>
                    <div className="safety__tableItem">
                        {languages.safetyMultiLayer}
                        {languages.safetyMultiLayerText}
                    </div>
                    <div className="safety__tableItem">
                        {languages.safety3Pairs}
                        <p>{languages.safety3PairsText}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}