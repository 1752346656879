import React, { useEffect } from "react";
import "./MetalPrices.scss";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "components/Spinner/Spinner";
import { fetchMetalPrices } from "Redux/reducers/metalsReducer";

export default function MetalPrices() {
    const languages = useSelector((state) => state.languages);
    const { metalsData, isLoading } = useSelector((state) => state.metals);

    const dispatch = useDispatch();

    const metalsForSale = ["USDXAU", "USDXAG", "USDXPD", "USDXPT"];

    const metalPrices = metalsForSale.map((rate, i) => {
        return (
            <div className="metalPrices__priceRow" key={i}>
                <div className="name">
                    <span>{rate.slice(4)}</span>
                    <label>{languages.metals[rate].name}</label>
                </div>
                <div className="price">
                    <span>{Number(metalsData?.rates?.[rate]).toFixed(2)} $</span>
                    <label>1 {languages.metals.measurments.ounce}</label>
                </div>
            </div>
        );
    });

    useEffect(() => {
        dispatch(fetchMetalPrices());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="metalPrices">
            {isLoading ? (
                <div className="metalPrices__spinner">
                    <Spinner />
                </div>
            ) : (
                <>
                    <div className="metalPrices__namesRow">
                        <label>{languages.metalPricesMetal}</label>
                        <label>{languages.metalPricesPriceOnMetal}</label>
                    </div>
                    {metalPrices}
                </>
            )}
        </div>
    );
}
