import React, { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import Spinner from "components/Spinner/Spinner";

const PurchaseCheckoutForm = ({ clientSecret, setSuccessfulPayment, languages }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            setError(null);
            setIsLoading(true);

            if (!stripe || !elements) {
                return;
            }

            const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                },
            });

            setIsLoading(false);

            if (result.error) {
                setError(result.error.message);
            } else {
                if (result.paymentIntent.status === "succeeded") {
                    setSuccessfulPayment();
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="checkout-form">
            {error && <h4 className="checkout-form__error">{error}</h4>}

            <div className="checkout-form__card-element">
                <CardElement
                    options={{
                        hidePostalCode: true,
                    }}
                />
            </div>

            {isLoading ? (
                <Spinner size="small" />
            ) : (
                <div className="checkout-form__button">
                    <button type="submit" className="grayButton" disabled={!stripe || !elements || isLoading}>
                        {languages.purchasePagePay}
                    </button>
                </div>
            )}
        </form>
    );
};

export default PurchaseCheckoutForm;
