import React from 'react';

import './Spinner.scss'

const Spinner = ({ size = '' }) => {
    return (
        <div className={`spinner ${size}`} />
    );
};

export default Spinner;