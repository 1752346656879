import React from "react";
import "./AboutService.scss";
import smallArrowRight from "../../imgs/smallArrowRight.svg";
import { useSelector } from "react-redux";

export default function AboutService(props) {
    let languages = useSelector((state) => state.languages);
    if (props.type === 1) {
        return (
            <div className="aboutService">
                <div className="container">
                    <h2>{languages.pictureWithTextAboutService}</h2>
                    {languages.aboutServiceSwiftOffers}
                    <div className="aboutService__row">
                        <div className="aboutService__square">
                            <h3 className="thin">{languages.aboutServiceStartingPrice}</h3>
                            <p>
                                <span>425 000</span> $
                            </p>
                        </div>
                        {languages.aboutServiceNoBuro}
                    </div>
                </div>
            </div>
        );
    }
    if (props.type === 2) {
        return (
            <div className="aboutService">
                <div className="container">
                    <h2>{languages.pictureWithTextAboutService}</h2>
                    <p className="aboutService__p">{languages.aboutServiceSwiftPlaces}</p>
                    <div className="aboutService__row">
                        <div className="aboutService__square">
                            <h3 className="thin">{languages.aboutServiceStartingPrice}</h3>
                            <p>
                                <span>425 000</span> $
                            </p>
                        </div>
                        {languages.aboutServiceNoBuro2}
                    </div>
                </div>
            </div>
        );
    }
    if (props.type === 3) {
        return (
            <div className="aboutService">
                <div className="container">
                    <h2>{languages.pictureWithTextAboutService}</h2>
                    {languages.aboutServiceWeSellSilver}
                    <div className="aboutService__pointsRow">
                        <div className="aboutService__point">
                            <h3 className="thin">01</h3>
                            <h3>{languages.aboutServiceLowPrem}</h3>
                        </div>
                        <div className="aboutService__point">
                            <h3 className="thin">02</h3>
                            <h3>{languages.aboutServiceSafeAndCert}</h3>
                        </div>
                        <div className="aboutService__point">
                            <h3 className="thin">03</h3>
                            <h3>{languages.aboutServiceIndividual}</h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    if (props.type === 5) {
        return (
            <div className="aboutService">
                <div className="container">
                    <h2>{languages.aboutServiceAboutConditions}</h2>
                    {languages.aboutServiceOurClientsShouldnt}
                    <div className="aboutService__pointsRow">
                        <div className="aboutService__point aboutService__other1">
                            <h3 className="thin">01</h3>
                            <h3>{languages.aboutServiceWhyWeCan}</h3>
                            {languages.aboutServiceWeTakesPlace}
                        </div>
                        <div className="aboutService__point aboutService__other1">
                            <h3 className="thin">02</h3>
                            <h3>{languages.aboutServiceConditionsUnic}</h3>
                            {languages.aboutServiceInEurope}
                        </div>
                        <div className="aboutService__point aboutService__other1">
                            <h3 className="thin">03</h3>
                            <h3>{languages.aboutServiceWhyWins}</h3>
                            <p className="margin">{languages.aboutServiceYouCanEconomy}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    if (props.type === 6) {
        return (
            <div className="aboutService">
                <div className="container">
                    <h2>{languages.aboutServiceAboutConditions}</h2>
                    <p className="aboutService__p aboutService__p-points aboutService__p-other2">
                        {languages.aboutServicePartnersFromLloyds}
                    </p>
                    <div className="aboutService__pointsRow aboutService__pointsRow-other2">
                        <div className="aboutService__point aboutService__other2">
                            <h3 className="thin">01</h3>
                            <h3>{languages.aboutServiceWhyWeCan}</h3>
                            {languages.aboutServiceLloydsSigned}
                        </div>
                        <div className="aboutService__point aboutService__other2">
                            <h3 className="thin">02</h3>
                            <h3>{languages.aboutServiceWeDifferent}</h3>
                            {languages.aboutServiceOtherServices}
                        </div>
                        <div className="aboutService__point aboutService__other2">
                            <h3 className="thin">03</h3>
                            <h3>{languages.aboutServiceWhyWins}</h3>
                            {languages.aboutServiceClientsWarranty}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    if (props.type === 7) {
        return (
            <div className="aboutService">
                <div className="container">
                    <h2>{languages.aboutServiceAboutConditions}</h2>
                    {languages.aboutServiceWithCombined}
                    <div className="aboutService__pointsRow">
                        <div className="aboutService__point aboutService__other1">
                            <h3 className="thin">01</h3>
                            <h3>{languages.aboutServiceWhyWeCan}</h3>
                            {languages.aboutServiceWeHaveStuff}
                        </div>
                        <div className="aboutService__point aboutService__other1">
                            <h3 className="thin">02</h3>
                            <h3>{languages.aboutServiceConditionsUnic}</h3>
                            {languages.aboutServiceNotAnyStore}
                        </div>
                        <div className="aboutService__point aboutService__other1">
                            <h3 className="thin">03</h3>
                            <h3>{languages.aboutServiceWhyWins}</h3>
                            <p className="margin">{languages.aboutServiceYouCanStore}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    if (props.type === 8) {
        return (
            <div className="aboutService">
                <div className="container">
                    <h2>{languages.aboutServiceAboutConditions}</h2>
                    {languages.aboutServiceWeAreNotStopping}
                    <div className="aboutService__pointsRow">
                        <div className="aboutService__point aboutService__other1">
                            <h3 className="thin">01</h3>
                            <h3>{languages.aboutServiceWhyWeCan}</h3>
                            <p>{languages.aboutServiceOurCompanyNot}</p>
                        </div>
                        <div className="aboutService__point aboutService__other1">
                            <h3 className="thin">02</h3>
                            <h3>{languages.aboutServiceConditionsUnic}</h3>
                            {languages.aboutServiceMoreSweden}
                        </div>
                        <div className="aboutService__point aboutService__other1">
                            <h3 className="thin">03</h3>
                            <h3>{languages.aboutServiceWhyWins}</h3>
                            {languages.aboutServiceClientCanTake}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    if (props.type === 9) {
        return (
            <div className="aboutService">
                <div className="container">
                    <div className="aboutService__pointsRow aboutService__pointsRow-other2">
                        <div className="aboutService__point aboutService__other2">
                            <h3 className="thin">01</h3>
                            <h3>{languages.aboutServiceWhyWeCan}</h3>
                            <p></p>
                            <button className="arrowButton">
                                <label>{languages.documentsReadDoc}</label>
                                <img alt="" src={smallArrowRight} />
                            </button>
                        </div>
                        <div className="aboutService__point aboutService__other2">
                            <h3 className="thin">02</h3>
                            <h3>{languages.aboutServiceWeDifferent}</h3>
                            <p>{languages.aboutServiceOurConcurents}</p>
                        </div>
                        <div className="aboutService__point aboutService__other2 aboutService__other5">
                            <h3 className="thin">03</h3>
                            <h3>{languages.aboutServiceWhyWins}</h3>
                            {languages.aboutServiceYouCanTakeES}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    if (props.type === 10) {
        return (
            <div className="aboutService">
                <div className="container">
                    <h2>{languages.aboutServiceAboutConditions}</h2>
                    {languages.aboutServiceStructureOf}
                    <div className="aboutService__pointsRow">
                        <div className="aboutService__point aboutService__other1">
                            <h3 className="thin">01</h3>
                            <h3>{languages.aboutServiceWhyWeCan}</h3>
                            <p>{languages.aboutServiceOurCompanyNotBank}</p>
                        </div>
                        <div className="aboutService__point aboutService__other1">
                            <h3 className="thin">02</h3>
                            <h3>{languages.aboutServiceWeDifferent}</h3>
                            <p className="margin noMargin">{languages.aboutServiceMoreOfConcurents}</p>
                        </div>
                        <div className="aboutService__point aboutService__other1">
                            <h3 className="thin">03</h3>
                            <h3>{languages.aboutServiceWhyWins}</h3>
                            <p className="margin">{languages.aboutServiceClientsNeeds}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
